@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Link {
  width: 100%;
}

.OptionRow {
  align-items: center;
  border-radius: 8px;
  border: 1px solid $border;
  padding: 8px 16px;
  justify-content: space-between;
}

.NameCol {
  display: flex;
  align-items: center;
  gap: 14px;
}

.ProfileAvatar.ProfileAvatar {
  width: 36px;
  height: 36px;
}

.Name {
  @include breakpoint(desktop) {
    font-size: 16px;
  }
}
