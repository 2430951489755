@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ButtonRow {
  display: flex;
  gap: 32px;
}

.Button {
  cursor: pointer;
}