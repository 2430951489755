@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PromoBlockWrapper {
  width: 100%;
  background: transparent;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @include breakpoint(desktop) {
    gap: 30px;
  }
}
