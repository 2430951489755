@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Center {
  flex-direction: column;
  align-items: center;

  & :global a.ant-typography {
    color: $textGrey;
  }

  img {
    max-width: 300px;
  }
}

.ModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  padding: 36px 0;

  .Center img {
    max-width: 256px;
  }
}

h3.ModalContentTitle {
  color: $primaryDark;
  font-weight: 600;
  font-size: 18px;
  margin: 0 auto 16px;

  @include breakpoint(desktop) {
    font-size: 24px;
  }
}

h3.Title {
  font-size: 18px;
  font-weight: 600;
  line-height: 112%;
  letter-spacing: -0.36px;
  margin: 0 0 24px;
}

.WhiteCol {
  flex-direction: column;
  align-items: flex-start;
}

.Form :global {
  .ant-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: auto;
    gap: 14px;

    .ant-row {
      row-gap: 15px;
    }
  }

  .ant-select-customize-input {
    width: 100%;
  }

  .ant-input-affix-wrapper {
    height: 56px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-row {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 14px;
  }

  @include breakpoint(desktop) {
    .ant-form-item {
      margin-bottom: 14px;
    }

    .ant-row {
      flex-direction: row;
      gap: 30px;
    }
  }
}

.BottomAlign {
  align-items: flex-end;
  margin-top: 14px;

  @include breakpoint(desktop) {
    margin-top: 0;
  }
}

.FloatLabel {
  height: auto;
}

.FloatLabelData {
  padding: 25px 16px 10px;
}

.FloatLabelDataPhone {
  padding: 0;
  
  input {
    padding: 26px 16px 16px 16px;
  }
}

.ClinicChoose {
  @include breakpoint(desktop) {
    margin-bottom: 14px;
  }
}

.TextArea {
  @include breakpoint(desktop) {
    height: 100%;
    margin-bottom: 14px;

    & :global {
      .ant-form-item,
      .ant-form-item-row,
      textarea.ant-input,
      .ant-form-item-control,
      .ant-form-item-control-input-content,
      .ant-form-item-control-input {
        height: 100%;
      }
    }
  }
}

.Agree span {
  font-size: 12px;
  line-height: 150%;
}

.Button {
  margin-bottom: 14px;
  height: 44px;
}

.Modal :global(.ant-modal-content) {
  background-color: $background;

  @include breakpoint(desktop) {
    svg {
      circle {
        fill: $background;
      }
    }
  }
}
