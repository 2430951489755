@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.AgeToggleRow {
  display: flex;
  align-items: center;
  gap: 4px;

  :global(.ant-switch .ant-switch-handle::before) {
    background-color: $primary;
  }

  :global(.ant-switch-inner) {
    background-color: $white;
    border: 1px solid $border;
  }

  article {
    cursor: pointer;
  }
}

.AgeToggleContainer {
  background: $lightBlue;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  padding: 1px;

  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
      display: none;
    }
  }
}

.AgeToggleContainer :global(.ant-tabs-nav) {
  margin: 0;
}

.AgeToggleContainer :global(.ant-tabs-nav),
.AgeToggleContainer :global(.ant-tabs-nav-wrap),
.AgeToggleContainer :global(.ant-tabs-nav-list) {
  width: 100%;
}

.AgeToggleContainer :global(.ant-tabs-tab.ant-tabs-tab) {
  border: none;
  justify-content: center;
}

.AgeToggleContainer :global(.ant-tabs-tab-active.ant-tabs-tab-active.ant-tabs-tab-active) {
  border-radius: 8px;
  border: 1px solid $lightBlue;
}

.AgeRow {
  width: 448px;
  border-radius: 6px;
  gap: 12px;
  max-width: 322px;
  height: auto;
}

.AgeTag {
  height: 32px;
  width: 95px;
}

.ChildrenLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AgeToggleContainer .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  display: none;
}

.AgeToggleContainer :global(.ant-tabs-nav::before) {
  display: none;
}

.Active {
  color: $primary;
}
