@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PatientChoiceRow,
.PatientChoiceCol {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.PatientChoiceRow {
  @include breakpoint(desktop) {
    flex-direction: row;
    background-color: $white;
    border-radius: 14px;
    gap: 30px;
    padding: 30px;
  }
}

.PatientChoiceCol {
  @include breakpoint(desktop) {
    width: 365px;
  }
}

.TextAgreement {
  font-size: 11px;
  font-weight: 400;
  display: block;
}

a.TextLink.TextLink.TextLink {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  color: $primary;
}

.SubmitButton {
  min-height: 44px;
}

.GosButton {
  width: 250px;
  height: 36px;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 10px;
  article {
    font-size: 12px;
  }
}

.WarnText {
  color: $red;
  width: 385px;
  height: 80px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.WarnNote {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $red;
  width: 214px;
  height: 26px;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $red;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 16px;
}

.Button {
  width: 100%;
  height: 36px;

  @include breakpoint(desktop) {
    width: 251px;
  }
}