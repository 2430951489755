@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.TabsContainer {
  :global(.ant-tabs-nav-list) {
    width: auto;
  }

  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: hidden;
    }
  }
}
