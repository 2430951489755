@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Area {
  padding: 12px;
  background-color: $white;
  border-radius: 14px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  width: 100%;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.Title {
  font-size: 16px;
  line-height: 18px;
  color: $textPrimary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  cursor: pointer;
  width: 100%;

  &.Open::after {
    transform: rotate(180deg);
  }

  &::after {
    transition: 0.2s ease all;
    content: '';
    background: url('../../../assets/Chevron.svg') center no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 6px;
  }
}

.Blocks {
  display: none;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 20px;

  &.Open {
    display: flex;
  }
}

.Block {
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border: 1px solid $border;
  border-radius: 14px;
  position: relative;
  flex-direction: column-reverse;

  @include breakpoint(desktop) {
    flex-direction: row;
    padding: 20px 16px;
    max-width: calc(50% - 7px);
  }

  :global(.ant-image) {
    flex-shrink: 0;
    max-width: 160px;
    max-height: 130px;
  }

  :global(.ant-typography) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -2%;
    margin-top: 26px;
  }
}

.Number {
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: $primary;
  border-radius: 50%;
  color: $white;
  left: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 18px;
}

.Bold {
  font-weight: 600;
}

