@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.BlockRow {
  width: 100%;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 18px;
  @include breakpoint(desktop) {
    margin-top: 0;
    border-radius: 14px;
    flex-direction: row;
    padding: 23px;
    gap: 24px;
  }

  :global(.ant-carousel) {
    width: 100%;
    @include breakpoint(desktop) {
      max-width: 375px;
    }
  }
}

.CarouselCol {
  width: 375px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Carousel {
  :global(.ant-image) {
    width: 100%;
  }
}

.MobileImage {
  max-width: 375px;
  height: 218px !important;
  object-fit: cover;
  border-radius: 6px;
  @include breakpoint(desktop) {
    min-height: 260px;
  }
}

.MobileImageStub {
  @include breakpoint(desktop) {
    max-width: 375px;
  }
}

.ClinicInfoCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  @include breakpoint(desktop) {
    max-width: 375px;
  }
}

.ClinicName {
  font-weight: 600;
  font-size: 16px;
  color: $primary;
  
  &:hover {
    cursor: pointer;
    color: $textPrimary;
  }
}

.ClinicFullName {
  color: $textPrimary;
}

.PromoRow {
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.LabelPromo {
  background-color: rgba($orange, 0.2);
  padding: 4px 10px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}

.LabelRating {
  font-size: 12px;
  font-weight: 600;
  @include breakpoint(desktop) {
    font-size: 14px;
  }
}

.LabelReviews {
  font-size: 12px;
  color: $primary;
  @include breakpoint(desktop) {
    font-size: 14px;
  }
}

.TagRow {
  gap: 5px;
}

.DocumentsCollapse {
  width: 100%;

  :global(.ant-collapse-content-box.ant-collapse-content-box) {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.SubTitle {
  width: 100%;
  :global(.ant-collapse-header-text) {
    font-weight: 600;
    font-size: 16px;
  }
  :global(.ant-collapse-header.ant-collapse-header) {
    padding: 0;
  }
}

.GreyText {
  font-size: 12px;
  color: $textGrey;
}

.ColoredText {
  color: $primary;
}

.SignUpButtonRowOutside {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  background-color: $white;
  position: fixed;
  bottom: 73px;
  left: 0;
  padding: 4px 14px;
  z-index: 2;
  border-bottom: 1px solid $border;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);

  @include breakpoint(desktop) {
    position: relative;
    bottom: unset;
    left: unset;
    padding: 0;
    gap: 30px;
    border-bottom: none;
  }
}

.SignUpButtonRowInsideMobile,
.SignUpButtonRowInsideDesktop {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  @include breakpoint(desktop) {
    flex-direction: column;
  }
}

.SignUpButtonRowInsideMobile {
  display: flex;
  @include breakpoint(desktop) {
    display: none;
  }
}

.SignUpButtonRowInsideDesktop {
  display: none;
  @include breakpoint(desktop) {
    display: flex;
  }
}

.MobileSignUpButton {
  width: 100%;
  height: 44px;
}

.ChatButton {
  max-width: 375px;
}

.Divider {
  margin: 0;
}

.ContacsBlockWrapper {
  width: 100%;
}

.Dots.Dots.Dots {
  bottom: -10px;
  li {
    border-radius: 50%;
    &:global(.slick-active) {
      button {
        background-color: $primary;
      }
    }
    width: 7px;
    height: 7px;
    button {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $greySliderDot;
    }
  }
}

.ClinicLogo.ClinicLogo {
  position: absolute;
  max-width: 90px;
  max-height: 90px;
  border-radius: 6px;
  bottom: 29px;
  left: 9px;
  background: $white;
  padding: 10px;
}

.InfoPlaceholderCol {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 375px;
}

.Icon {
  width: 16px;
}

.EmptyImageAndLogo image {
  @media (max-width: 1023px) {
    width: calc(100vw - 52px) !important;
    max-height: 175px !important;
  }

  @media (max-width: 430px) {
    width: calc(100vw - 52px) !important;
  }
}
