@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.Button {
  width: 100%;
  max-width: 347px;
  height: 44px;
  border-radius: 10px;
  margin-bottom: 10px;

  @include breakpoint(desktop) {
    max-width: 400px;
  }
}

.ButtonsBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  margin-top: 14px;
}
