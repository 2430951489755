@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.SliderRow {
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.SliderRow::-webkit-scrollbar {
  @include breakpoint(desktop) {
    display: none;
  }
}

.SliderRow {
  @include breakpoint(desktop) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
