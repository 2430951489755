@import 'src/styles/mixins';
@import 'src/app/theme.scss';

@media (min-width: 1024px) {
 
  .Form {
    padding: 50px 0;
  }

}

.DocumentsModalRecordWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  padding-bottom: 30px;
}

.InputRow {
  display: flex;
  flex-direction: row;
  gap: 14px;
  justify-content: space-between;
  width: 347px;

  @include breakpoint(desktop) {
    width: 400px;
  }

  :global(.ant-form-item) {
    width: 100%;
    margin-bottom: auto;
  }
}

.FieldsBlock {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  gap: 14px;
}

.DocumentsModalRecordTitle {
  font-weight: 600;
  font-size: 18px;
  color: $primaryDark;
  margin: 0 auto 14px;

  @include breakpoint(desktop) {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.SaveButtonWrapper {
  align-self: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.SaveButton {
  width: 100%;
  max-width: 347px;
  height: 44px;
  border-radius: 10px;

  @include breakpoint(desktop) {
    max-width: 400px;
  }
}

.DocsAccessEnable {
  width: 100%;
  max-width: 347px;
  align-self: center;
  gap: 10px;
  align-items: center;

  @include breakpoint(desktop) {
    max-width: 400px;
  }

  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.FilesWrapper {
  flex-direction: column;
  gap: 14px;
  border-radius: 12px;
  border: 1px solid $primary;
  padding: 12px;
  width: 100%;
  max-width: 347px;
  margin-bottom: 14px;

  @include breakpoint(desktop) {
    max-width: 400px;
  }
}

.FileRow {
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.FileCol {
  display: flex;
  align-items: center;
  gap: 8px;
}

.RemoveButton.RemoveButton {
  width: 21px;
  height: 21px;
  padding: 5px;
  display: flex;
}

.InputOrganizationName {
  padding: 24px 16px 10px;
  height: 50px;
}

.DocumentNameLabel {
  color: $primary;
}

.LoadingRow {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (min-width: 1024px) {
  .TabBtn {
  }
  .ant-tabs {
    height: 46px;
  }
  .ant-tabs {
    height: 46px;
    .ant-tabs-nav {
      height: 100% !important;
      .ant-tabs-nav-list {
        height: 46px;
      }
    }
  }

  .TabsContainer {
    :global(.ant-tabs-nav-list) {
      height: 46px !important;
    }
  }
  [class*='ant-tabs'][class*='nav'] {
    height: 100% !important;
  }
}
.PreviewDocumentModal {
  width: 100% !important;
}

.PdfViewModal {
  :global(.ant-modal-body) {
    max-height: calc(100vh - 90px) !important;
  }
}

@media print {
  .rpv-print__body-printing .PreviewDocumentModal {
    display: none;
  }

  .rpv-print__body-printing .ShareBtn.SaveButton {
    display: none;
  }
}

.ShareBtn {
  display: none;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1024px) {
    display: block !important;
  }
}

.PrintBtn {
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1024px) {
    display: none !important;
  }
}
