@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.ModalContainer :global(.ant-modal-content) {
  padding: 14px;
}

.ModalContainer :global(.ant-modal-body) {
  display: flex;
  flex-direction: column;
}

.ModalContentWrapper {
  background: transparent;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 12px 0;

  @include breakpoint(desktop) {
    width: 400px;
    align-self: center;
    padding: 36px 0;
  }
}

.ModalContentTitle {
  color: $primaryDark;
  font-weight: 600;
  font-size: 18px;

  @include breakpoint(desktop) {
    font-size: 24px;
  }
}

.MapTitle {
  font-size: 16px;
  font-weight: 600;
}

.SubTitle {
  font-size: 14px;
  font-weight: 600;
  align-self: flex-start;
}

.Search {
  width: 100%;
}

.Select {
  width: 100%;
  :global(div.ant-select-selector.ant-select-selector) {
    height: 50px;
  }
}

.TabsContainer :global(.ant-tabs-tab.ant-tabs-tab) {
  width: 172px;
  @include breakpoint(desktop) {
    width: 200px;
  }
}

.MapRow {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  border: 1px solid $border;
  border-radius: 14px;
  padding: 12px;
  width: 100%;
}

.ContactRow {
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 5px;
}

.Icon {
  margin-top: 4px;
}

.GreyText {
  font-size: 12px;
  color: $textGrey;
}

.ServicesRow {
  overflow: scroll;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  @include breakpoint(desktop) {
    position: relative;
    overflow: hidden;
    padding: 10px 0;
  }
}

.MapWrapper.MapWrapper {
  padding: 0;
  max-height: 130px;
  min-height: 130px;

  :global(.ymaps-2-1-79-map) {
    width: 100%;
  }
}
