@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.StatisticsWrapper {
  width: 100%;
}

h3 {
  margin: 0 auto;
}

.BlockRow {
  width: 559px;
  height: 260px;
  display: none;
  flex-direction: row;
  padding: 20px;
  flex-wrap: nowrap;
  gap: 19px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

  @include breakpoint(desktop) {
    display: flex;
  }
}

.BlockRowMobile {
  width: 100%;
  display: flex;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

  @include breakpoint(desktop) {
    display: none;
  }
}

.DocumentsBlock {
  width: 141px;
  height: 100%;
}

.DocumentsBlockMobile {
  height: 70px;
  background-color: $background;
  border-radius: 14px;
  width: 100%;
  padding: 5px 18px 5px 10px;
}

.DocumentsBlockWrapper {
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  flex-wrap: nowrap;

  @include breakpoint(desktop) {
    flex-direction: column;
    padding: 35px 36px 31px 34px;
    border: 3px solid $border;
  }
}

.StatisticsBlock {
  width: 359px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 27px;
}

.DocumentsText {
  font-size: 12px;
  line-height: 130%;
  color: $primaryDark;
  word-break: keep-all;
  text-align: start;
  max-width: 82px;

  @include breakpoint(desktop) {
    color: $textGrey;
    text-align: center;
  }
}

.DocumentsCountText {
  font-size: 50px;
  line-height: 60px;
  color: $primaryDark;
  white-space: nowrap;
  margin-right: 16px;

  @include breakpoint(desktop) {
    font-size: 60px;
    margin-top: 19px;
    margin-bottom: 9px;
    margin-right: 0;
  }
}

.DocumentsLastDateText {
  font-size: 12px;
  line-height: 18px;
  color: $textGrey;
  white-space: nowrap;
  margin-left: auto;

  @include breakpoint(desktop) {
    margin-left: 0;
  }
}

.StatisticsBlockTitle {
  color: $primaryDark;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}

.StatisticsColumn {
  width: 85px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.VerticalDivider {
  height: 50px;
  margin: 10px 0;

  @include breakpoint(desktop) {
    margin: 0;
  }
}

.StatisticsBlocksRow {
  justify-content: space-between;
}

.StatisticsText {
  font-size: 11px;
  line-height: 130%;
  color: $textGrey;
  text-align: start;
  word-break: keep-all;
  width: 105px;

  @include breakpoint(desktop) {
    width: auto;
    text-align: center;
  }
}

.StatisticsCountText {
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  word-break: keep-all;
}

.ImportantInfoBlock {
  background-color: $white;
  width: 100%;
  border-radius: 14px;
  padding: 20px;
  flex-wrap: nowrap;
  gap: 12px;
  border: 1px solid $border;

  svg {
    height: 30px;
    width: 30px;
    @include breakpoint(desktop) {
      height: 38px;
      width: 38px;
    }
  }

  @include breakpoint(desktop) {
    width: 359px;
    gap: 20px;
    background-color: $background;

    svg {
      height: auto;
      width: auto;
    }
  }
}

.ImportantInfoText {
  color: $textGrey;
  font-size: 12px;
  line-height: 16px;
}

.HorizontalDivider {
  margin: 0;
  width: 100%;
}

.StatisticsColumnsWrapper {
  width: 100%;
}

.StatisticsMobileColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 14px;
  width: 151.5px;
  max-width: calc(50% - 1px);
  margin-right: auto;
}

.StatisticsRow {
  width: 100%;
  justify-content: space-around;
}

.DocumentsLoaderBlock {
  display: flex;
  gap: 5px;
  :global(.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-sm) {
    width: 8px;
    height: 8px;
    line-height: 8px;
  }
}
