@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DocumentsModalRecordWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.DocumentsModalRecordTitle {
  font-weight: 600;
  font-size: 24px;
  color: $primaryDark;
  margin: 0 auto 14px;

  @include breakpoint(desktop) {
    padding-bottom: 20px;
    padding-top: 30px;
    margin-bottom: 0;
  }
}

.MilaMessageWrapper {
  max-width: 347px;
  margin: 0 auto;

  @include breakpoint(desktop) {
    max-width: 400px;
  }
}

.ClinicDocsBlock,
.UserDocsBlock {
  max-width: 347px;
  min-height: 146px;
  background-color: $white;
  width: 100%;
  margin: 14px auto;
  box-shadow: 0 1px 5px rgba($black, 0.05);
  border-radius: 14px;
  padding: 12px;
  align-content: space-between;

  @include breakpoint(desktop) {
    max-width: 400px;
  }
}

.DocumentsUploadedTitle {
  font-size: 16px;
  font-weight: 600;
}

.DocumentsFromClinicText {
  color: $textGrey;
  margin: 14px 0;
}

.UploadDocButton,
.RequestDocuments {
  width: 100%;
  max-width: 400px;
  height: 44px;
  border-radius: 10px;

  @include breakpoint(desktop) {
    width: 400px;
  }
}

.DocsListContainer {
  width: 100%;
  max-width: 347px;
  align-self: center;
  margin-bottom: 20px;

  @include breakpoint(desktop) {
    max-width: 400px;
  }
}

.AddDocButton {
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }
}
