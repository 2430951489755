@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.SpecialtiesWrapper {
  background: transparent;
  min-height: auto;
}

.ListWrapper {
  background: transparent;
  min-height: auto;
}

.TabsContainer {
  max-height: 42px;
}

.TabsContainer :global(.ant-tabs-tab.ant-tabs-tab) {
  width: 50%;
}

.InteractiveRow {
  gap: 10px;
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}

.SearchInput {
  width: 100%;
  height: 50px;
  @media (min-width: 1024px) {
    width: 50%;
  }
}

.Select {
  width: 100%;
  @media (min-width: 1024px) {
    width: 50%;
  }
}

.Select :global(div.ant-select-selector.ant-select-selector) {
  height: 50px;
  display: flex;
  align-items: center;
}

li.SpecialtyChoiceListItem.SpecialtyChoiceListItem {
  padding: 14px 16px;
}
