@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.CalendarWrapper {
  overflow-x: scroll;
  width: 100%;
  background: $white;
  gap: 40px;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  border-radius: 14px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  padding: 12px;
}

.CalendarWrapper::-webkit-scrollbar {
  @include breakpoint(desktop) {
    display: none;
  }
}

.CalendarWrapper {
  @include breakpoint(desktop) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.CalendarCol {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: fit-content;
}

.MonthLabel {
  color: $textGrey;
  width: 32px;
  text-align: center;
  word-break: keep-all;
}

.YearLabel {
  min-width: 30px;
  color: $textGrey;
  font-size: 12px;
}

.Row {
  gap: 8px;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
}

.MonthRow {
  gap: 8px;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 38px;
}

.CalendarCell.CalendarCell.CalendarCell {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: $background;
  border: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    padding: 0;
    border: none;
    background: transparent;
  }
}

.RecordsRow {
  flex-direction: column;
}

.LegendContainer {
  border-radius: 14px;
  border: 1px solid $border;
  width: 243px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.LegendRow {
  align-items: center;
  gap: 8px;
}

.LegendDot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.LegendText {
  font-size: 12px;
  color: $textGrey;
}

.ClickableText {
  color: $primary;
  cursor: pointer;
  align-self: center;
  font-weight: 600;
}

.Divider {
  margin: 0;
}
