@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DocumentsList {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.DocumentNameLabel {
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
  margin-left: 11px;
  max-width: 255px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.DocumentItemWrapper {
  display: flex;
  align-items: center;

  :global {
    .ant-btn-default {
      border-color: $buttonBorder;
      border-radius: 40px;
      width: 22px;
      height: 18px;
      display: flex;
      justify-content: center;
    }
  }
}
