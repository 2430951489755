@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Area {
  padding: 12px;
  background-color: $white;
  border-radius: 14px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  width: 100%;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.Block {
  border: 1px solid $border;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  margin-top: 20px;
}

.Info {
  display: flex;
  margin-bottom: 10px;
  max-width: 80%;
}

.Data {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.Title {
  font-size: 14px;
  line-height: 18px;
  color: $primary;
  margin-bottom: 8px;

  display: flex;
  gap: 4px;

  span {
    color: $textGrey;
  }
}

.BlockTitle {
  font-size: 16px;
  line-height: 18px;
  color: $textPrimary;
  font-weight: 600;
  width: 100%;

  span {
    color: $textPrimary;
  }
}

.Text {
  color: $textGrey;
  font-size: 12px;
  line-height: 16px;
}

.MessageHeader {
  font-size: 12px;
  line-height: 16px;
  color: $textPrimary;
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
  margin-top: 12px;

  &.Open::after {
    transform: rotate(180deg);
  }

  &::after {
    transition: 0.2s ease all;
    content: '';
    background: url('../../../assets/Chevron.svg') center no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 6px;
  }
}

.MessageBody {
  margin-top: 12px;
  font-size: 14px;
  line-height: 18;
  letter-spacing: -2%;
  color: $textGrey;
  display: none;
  flex-direction: column;

  &.Open {
    display: flex;
  }

  &>:global(.ant-typography):not(:last-child) {
    margin-bottom: 12px;
  }
}

.OkNote {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $textPrimary;
  width: fit-content;
  height: fit-content;
  padding: 4px 10px 4px 5px;
  border-radius: 40px;
  border: 1px solid $green;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-top: 16px;
  gap: 12px;

}

.OkIconContainer {
  border-radius: 50%;
  background: $green;
  min-width: 22px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}


