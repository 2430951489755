@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.HeaderRow {
  display: none;
  width: 100%;
  background-color: $white;
  border-radius: 0 0 15px 15px;
  border-bottom: 1px solid $divider;

  @include breakpoint(desktop) {
    display: block;
    border-radius: 0;
    min-height: 120px;
  }
}

.WorkArea {
  min-height: 50px;

  @include breakpoint(desktop) {
    min-height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 1160px;
    margin: 0 auto;

    padding: 10px;
  }
}

.MainCol {
  width: 100%;

  @include breakpoint(desktop) {
    max-width: 850px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.MilaIcon {
  display: none;
  cursor: pointer;
  max-width: 174px;
  max-height: 100px;
  @include breakpoint(desktop) {
    display: inline;
  }
}

.MilaIconMobile {
  display: inline;
  @include breakpoint(desktop) {
    display: none;
  }
}

.MenuCol {
  display: none;
  @include breakpoint(desktop) {
    display: inline;
  }
}

.IconCol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 260px;
  flex-shrink: 0;
}

.GreetingRow {
  display: flex;
  gap: 10px;
  @include breakpoint(desktop) {
    display: none;
  }
}

.GreetingText {
  font-size: 14px;
  color: $white;
}

.Button {
  cursor: pointer;
}

.HeaderButtonContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: auto;

  svg {
    &:hover {
      path {
        stroke: $menuHover;
      }
    }

    path {
      stroke: $textGrey;
    }
  }
}

.HeaderIcon {
  path {
    stroke: $primary;
  }
}

.HeaderLinkText {
  color: $primary;
  line-height: 130%;
}

.HeaderLinkTag {
  gap: 8px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid $primary;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 15px;
}

.SharedMenuCol {
  display: flex;
  flex-direction: row;
  gap: 19px;
  width: 100%;
  justify-content: space-between;
}

.CreateMedcardCardButton {
  height: 44px;
  margin-left: auto;
}
