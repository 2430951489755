.CalendarRecordsListWrapper {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;

  :global {
    .ant-row {
      width: 100%;
    }
  }
}
