@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PrepWrapper {
  min-height: auto;
  background: $white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  border-radius: 14px;
  box-shadow: 0 1px 5px rgba($black, 0.05);
  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.Title {
  font-size: 16px;
  font-weight: 600;
}

.Tip {
  max-width: 627px;
}