@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Area {
  padding: 12px;
  background-color: $white;
  border-radius: 14px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  width: 100%;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.Title {
  font-size: 16px;
  line-height: 18px;
  color: $textPrimary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  cursor: pointer;
  width: 100%;

  &.Open::after {
    transform: rotate(180deg);
  }

  &::after {
    transition: 0.2s ease all;
    content: '';
    background: url('../../../assets/Chevron.svg') center no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 6px;
  }
}

.Text {
  display: none;
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -2%;

  &.Open {
    display: flex;
  }
}
