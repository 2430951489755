@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ModalRowWrapper {
  background: transparent;
  align-items: center;
  padding-bottom: 15px;
  @include breakpoint(desktop) {
    padding: 50px;
  }
}

.ModalRow {
  align-items: center;
  flex-direction: column;
  gap: 15px;
  @include breakpoint(desktop) {
    max-width: 400px;
    gap: 20px;
  }
}

.ModalTitle {
  font-weight: 600;
  font-size: 18px;
  color: $primaryDark;
  text-align: center;
}

.ModalSubTitle.ModalSubTitle {
  text-align: center;
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;
  font-size: 16px;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.ImageRow {
  justify-content: center;
}

.ModalAboutMila {
  text-align: center;
  max-width: 314px;
}

.ModalCoupon {
  padding: 10px 30px;
  border-radius: 8px;
  border: 1.5px dashed $lightBlue;
  background: $white;
  display: flex;
}

.ModalMessage {
  margin: 2px 0 6px;
}

.ModalMessageInfo {
  display: flex;
  flex-direction: column;
}

.ModalMessageText {
  color: $textGrey;

  &.ModalMessageBoldText {
    color: $textPrimary;
  }
}

.SearchRow {
  padding: 12px;
  border-radius: 14px;
  background-color: $white;
}

.Share {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background: $white;
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid $border;
  text-align: left;

  & :global {
    .ya-share2__title {
      font-size: 14px;
    }
  }

  @include breakpoint(desktop) {
    bottom: 113px;
  }
}

.AddNewButton {
  min-height: 44px;
  width: 100%;
}

.ImageIcon {
  max-width: 256px;
}
