@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PrepRow {
  flex-wrap: nowrap;
  gap: 10px;
  border: 1px solid $border;
  border-radius: 4px 10px 10px 10px;
  padding: 14px 12px;
  box-shadow: 0 3px 5px rgba($black, 0.05);
  width: 100%;
  background-color: $white;
}

.TitleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 33px;
}

.Clickable {
  cursor: pointer;
  flex-wrap: nowrap;
  transition: 0.3s;
  &::after {
    content: '';
    background: url('../../../assets/Chevron.svg') center no-repeat;
    width: 15px;
    height: 15px;
  }
}

.Clickable.Open {
  &::after {
    transform: rotate(180deg);
  }
}

.Title {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  @include breakpoint(desktop) {
    font-size: 16px;
  }
}

.TextCol {
  width: 100%;
}

.Text {
  font-size: 14px;
  font-weight: 400;
  color: $textGrey;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  @include breakpoint(desktop) {
    padding-right: 30px;
  }

  span {
    margin-bottom: 20px;
    color: $textGrey;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $primary;
    white-space: nowrap;
  }
}

.MilaIcon {
  max-width: 30px;
}
