@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ButtonsRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
  gap: 10px;
  @include breakpoint(desktop) {
    margin-top: 0;
    max-width: 450px;
  }
}

.EstimateModalWrapper {
  :global(.ant-layout) {
    @include breakpoint(desktop) {
      margin: 36px 66px;
    }
  }

  :global(.ant-modal-content) {
    background-color: $background;
  }
}

.DedicatedDocumentsModalContainer {
  @include breakpoint(desktop) {
    max-height: 652px;
  }

  :global {
    .ant-modal-content {
      background-color: $background;
      width: 100%;
      min-height: 375px;
      border-radius: 14px 14px 0 0;

      @include breakpoint(desktop) {
        border-radius: 20px;
        width: 620px;
        min-height: auto;

        svg {
          circle {
            fill: $background;
          }
        }
      }
    }
  }
}

.DocumentsModalContainer {
  @include breakpoint(desktop) {
    max-height: 780px;
  }

  :global {
    .ant-modal-content {
      width: 100%;
      min-height: 375px;
      border-radius: 14px 14px 0 0;

      @include breakpoint(desktop) {
        border-radius: 20px;
        width: 620px;
        min-height: 652px;
      }
    }
  }
}

.Button {
  width: 100%;
  height: 36px;

  @include breakpoint(desktop) {
    width: auto;
    min-width: 210px;
  }
}

.OrangeButton {
  background: $connectButton;
}

