@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DoctorRow {
  position: relative;
  width: 100%;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;

  @include breakpoint(desktop) {
    flex-direction: row;
    padding: 23px;
    gap: 30px;
  }
}

.InfoRow {
  width: 100%;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border-radius: 14px;

  & > div {
    width: 100%;
  }

  @include breakpoint(desktop) {
    border-radius: 14px;
    flex-direction: row;
    padding: 22px 30px;
  }
}

.DoctorPhotoContainer {
  width: 100%;
  @include breakpoint(desktop) {
    width: auto;
  }
}

.DoctorPhoto.DoctorPhoto.DoctorPhoto {
  height: 200px;
  border-radius: 10px;
  object-fit: contain;

  @include breakpoint(desktop) {
    width: 375px;
    height: 260px;
  }
}

.DoctorSummaryBlock {
  width: 100%;
  flex-grow: 1;
  @include breakpoint(desktop) {
    width: auto;
  }
}

.DesktopSignUpButton {
  width: 100%;
  display: none;
  margin-top: 18px;
  height: 44px;
  @include breakpoint(desktop) {
    display: block;
  }
}

.MobileSignUpButtonRow {
  width: 100%;
  display: block;
  background-color: $white;
  position: fixed;
  bottom: 73px;
  left: 0;
  padding: 4px 14px;
  border-bottom: 1px solid $border;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  @include breakpoint(desktop) {
    display: none;
  }
}

.MobileSignUpButton {
  width: 100%;
  height: 44px;
}

.Schedule.Schedule {
  flex-grow: 1;
  max-width: none;
  width: 100%;
}

.TabsContainer {
  :global(.ant-tabs-nav-operations.ant-tabs-nav-operations) {
    display: none;
  }
}

.ScrollWrapper {
  @include breakpoint(desktop, max) {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    height: 410px;
  }
}

.ScrollWrapper::-webkit-scrollbar {
  display: none;
}

.ScrollWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
