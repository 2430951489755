@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.StubContainer {
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.BoldText {
  font-weight: 600;
  font-size: 16px;
}

.Text {
  color: $textGrey;
  text-align: center;
  max-width: 481px;
  margin-bottom: 30px;
}

.Image {
  max-width: 160px;
  margin-bottom: 30px;

  @include breakpoint(desktop) {
    max-width: 256px;
  }
}

.ButtonRow {
  align-items: center;
  gap: 10px;
}

.Plus {
  path {
    stroke: $white;
  }
}

.Button {
  height: 44px;
}

.AddClinicModal :global(.ant-modal-content) {
  background-color: $background;
}
