@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.profileSidebar {
  display: none;

  @include breakpoint(desktop) {
    display: flex;
    top: 10px;
    position: sticky;
    background: $white;
    border-radius: 20px;
    padding: 20px 15px;
    flex-direction: column;
    width: 100%;
    max-width: 260px;
  }
}

.profiles {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 14px;
  border: 1px solid $background;
  border-radius: 10px;
  gap: 3px;
  flex-wrap: nowrap;
  &:hover {
    cursor: pointer;
    background-color: $background;
  }
}

.info {
  display: flex;
  gap: 8px;
}

.texts {
  max-width: 117px;
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 11px;
  line-height: 133%;
  color: $textGrey;
  text-align: start;
}

.name {
  color: $textPrimary;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  margin-bottom: 6px;
}

.addProfile {
  padding: 16px 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;

  :global {
    .ant-image {
      min-width: 50px;
      height: 50px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $background;
      border-radius: 50%;

      svg {
        width: 18px;
        height: 18px;
        path {
          stroke: $primary;
        }
      }
    }
  }
}

.Avatar.Avatar {
  width: 50px;
  height: 50px;
}
