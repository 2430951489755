@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DoctorReviewsWrapper {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
