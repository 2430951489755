@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.RoutingButton {
  height: 36px;
  min-width: 36px;
  max-width: 36px;
  padding: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: $background;
}
