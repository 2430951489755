@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Title.Title {
  font-weight: 600;
  font-size: 14px;
}

.NoMedcardRow {
  bottom: 0;
  @include breakpoint(desktop) {
    max-width: 260px;
  }
}

.NoMedcardRow,
.NoMedcardCol {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
}

.NoMedcardCol {
  text-align: center;
  padding: 20px 15px;
  border-radius: 14px;
  border: 1px solid $border;
}

.Button {
  height: 36px;
  width: 100%;

  @include breakpoint(desktop) {
    height: 44px;
  }
}

.Link.Link {
  color: $primary;
}

.Promocode {
  border-radius: 10px;
  border: 1.5px dashed $greyBackground;
  width: 100%;
  padding: 14px 24px;
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
}

.Share {
  position: absolute;
  bottom: 134px;
  left: 50%;
  transform: translateX(-50%);
  background: $white;
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid $border;
  text-align: left;

  & :global {
    .ya-share2__title {
      font-size: 14px;
    }
  }
}
