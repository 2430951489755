@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ClinicIconRow {
  flex-direction: column;
  gap: 10px;
  text-align: center;
  min-width: 109px;

  :global(.ant-image),
  .Placeholder {
    border-radius: 20px;
    border: 1px solid $border;
    background: $white;
    width: 109px;
    height: 109px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Image.Image {
  padding: 12px;
}

.Placeholder {
  cursor: default;
}

.ClinicName {
  max-width: 110px;
  max-height: 68px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}
