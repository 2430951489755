@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.UploadFileButtonWrapper {
  align-self: center;
  display: flex;
  width: 100%;

  :global(.ant-upload-select) {
    width: 100%;
    max-width: 347px;
    margin: 0 auto;
    display: block;

    @include breakpoint(desktop) {
      max-width: 400px;
    }
  }

  :global(.ant-upload-wrapper) {
    width: 100%;
  }

  :global(.ant-form-item) {
    width: 100%;
    margin-bottom: 0;
  }

  :global(.ant-upload-list-text.ant-upload-list-text) {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  :global(.ant-upload-list) {
    max-width: 347px;
    margin: 0 auto;

    @include breakpoint(desktop) {
      max-width: 400px;
    }
  }

  :global(.ant-form-item-explain-error) {
    max-width: 347px;
    margin: 0 auto;

    @include breakpoint(desktop) {
      max-width: 400px;
    }
  }
}

.AddDocButton {
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }
}

.progress-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 24px;
}

.closeIcon {
  border: 1px solid #d9d9d9;
  border-radius: 50px !important;
  background-color: white;
  width: 21px;
  height: 21px;
  padding: 5px;
  display: flex;
}
.filename {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgb(127, 73, 222);
  padding: 10px 20px;
}
.filename2 {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}
.uploadBtn {
  width: 347px;
  gap: 16px;
  border-radius: 10px;
  padding: 14px 24px;
}
// @media (min-width: 1024px) {
//   .labelUpload {
//     height:46px;
//   }
//   .uploadBtn {
//     width: 400px;
//     height:46px;
//   }
// }

.labelUpload {
  width: 400px;
}
@media (max-width: 767px) {
  .labelUpload {
    width: 347px;
  }
}

@media (min-width: 1024px) {
  .antRow {
    width: 400px;
   

    label[for='fileUpload'] {
      width: 400px;
      height:46px;

      .uploadBtn {
        width: 400px;
        height:46px;
      }
    }
  }
}
.antRow {
  width: 400px;
}
@media (max-width: 767px) {
  .antRow {
    width: 347px;
  }
}

.docNameIcon {
  display: flex;
  gap: 10px;
}
.upload_btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom_progress_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-progress-outer {
    margin: 0;
    padding: 0;

    .ant-progress-inner {
      margin: 20px;
      border-radius: 10px !important;
      .ant-progress-bg {
        margin: 40px;
        border-radius: 10px !important;
      }
    }
  }
  .ant-progress-text {
    position: absolute;
    color: white;
    left: calc(50% - 14px);
    top: calc(50% - 8px);
  }
}
