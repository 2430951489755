@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.EmptyImagesBlock {
    width: 375px;
    height: 260px;
    background: url('../../../assets/ClinicStubWithoutLogo.png');
    background-size: cover;

    @media (max-width: 430px) {
        width: calc(100vw - 52px);
        height: 218px !important;
    }

    :global(.ant-card-body) {
        width: inherit;
        height: inherit;
        padding: 7px !important;
    }
}

.EmptyImagesBlockWithouthLogo {
    background: url('../../../assets/ClinicStub.png') !important;
    background-size: cover !important;
}

.EmptyImagesBlockLogoWrapper {
    background: $white;
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: 7px;
    border-radius: 6px;
    padding: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px !important;
}

.EmptyImagesBlockLogo {
    max-width: 90px !important;
    background: transparent !important;
}
