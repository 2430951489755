@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.TagWrapper {
  background: transparent;
  border-radius: 14px;
  width: fit-content;
  padding: 2px 10px;
  border-color: $red;
  color: $red;
}
