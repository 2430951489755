@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Slider {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 14px;
  width: 100%;

  & :global {
    .slick-slider {
      width: 100%;
      @include breakpoint(desktop) {
        max-width: 87%;
      }
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-track {
      display: flex;
    }
  }
}
