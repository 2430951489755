@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DocumentNameLabel {
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
  margin-left: 11px;
  max-width: calc(100% - 20px);
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Icon {
  min-width: 20px;
}

.DocumentItemWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  :global {
    .ant-btn-default {
      border-color: $buttonBorder;
      border-radius: 40px;
      width: 22px;
      height: 18px;
      display: flex;
      justify-content: center;
    }
  }
}
