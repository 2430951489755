@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PaleText {
  color: $textGrey;
  font-size: 11px;
  max-width: 280px;

  a {
    font-size: 11px;
    color: $textGrey;
    text-decoration: underline;
  }
}
