@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ModalMessageLinkText.ModalMessageLinkText {
  color: $primary;
}

.ModalMessageInfo {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.ModalMessageText {
  color: $textGrey;
  text-align: left;
  @include breakpoint(desktop) {
    max-width: 366px;
  }
}

.ModalMessageSmallText {
  font-size: 11px;
  color: $textGrey;
  text-align: left;
  @include breakpoint(desktop) {
    max-width: 366px;
  }
}
