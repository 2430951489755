@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.SpecialtyRow {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: flex-start;
}

.ListContainer {
  width: 100%;
  padding-top: 0;
  min-height: auto;
}

.ListItem.ListItem {
  padding: 14px 0;
  width: 100%;
  cursor: pointer;
}

.ListItem:hover {
  background-color: rgba($primary, 0.12);
}

.ListItem:active {
  background-color: rgba($primary, 0.24);
}

.SpecialtyName {
  color: $primary;
}

.DoctorAmount {
  color: $textPale;
}

.Price {
  font-weight: 400;
  font-size: 14px;
  color: $textPrimary;
  background-color: rgba($orange, 0.2);
  border-radius: 40px;
  padding: 4px 10px;
}

.Caret {
  margin-left: 5px;
}

.RightCol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.LeftCol {
  flex-grow: 1;
  display: flex;
  gap: 8px;
  margin-right: 8%;
  flex-direction: column;
}

.Ruble {
  margin-left: 4px;
}

.InnerRow {
  gap: 15px;
  align-items: center;
}
