@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.TypeTabs {
  width: 100%;
  :global(.ant-tabs-nav-operations.ant-tabs-nav-operations) {
    display: none;
  }
}

.Title {
  font-size: 16px;
  font-weight: 600;

  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.Tabs {
  :global(.ant-tabs-top > .ant-tabs-nav::before) {
    border-bottom-color: $buttonBorder;
  }
}

.PromoBlockWrapper {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  gap: 20px;
  @include breakpoint(desktop) {
    gap: 30px;
  }
}

.PromoExample {
  border-radius: 14px;
}

.NoDoctors {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: $white;
  padding: 30px;
  border-radius: 14px;
  box-shadow: 0 1px 5px rgba(0,0,0,.05);
}

.NoDoctorsImage {
  max-width: 256px;
  margin: 45px 0 30px;
}

.NoDoctorsTitle {
  color: $textPrimary;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.NoDoctorsText {
  color: $textGrey;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.28px;
}

.AddClinicButton {
  background-color: $primary;
  width: 347px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  margin: 20px 0 10px;
  justify-content: center;

  span {
    color: $white;
  }
}

.AddClinicModal :global(.ant-modal-content) {
  background-color: $background;
}
