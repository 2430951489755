@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.UserRow {
  //overwriting inline styles
  display: flex !important;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 12px;
  min-width: 100vw;
  padding-right: 28px;

  @include breakpoint(desktop) {
    flex-direction: row;
    flex-flow: nowrap;
  }
}

.Avatar.Avatar {
  width: 100px;
  height: 100px;
}

.AvatarEditable {
  cursor: pointer;
}

.AvatarText {
  font-size: 30px;
  letter-spacing: -0.6px;
}

.NameBlockWrapper {
  align-items: center;
  justify-content: center;

  @include breakpoint(desktop) {
    justify-content: flex-start;
  }
}

.EditButton {
  display: none;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  @include breakpoint(desktop) {
    display: flex;
  }
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
}

.SliderDot {
  :global {
    display: flex !important; // to override default react-slick style
    gap: 10px;
    justify-content: center;
    padding: 0px;
  }

  :global {
    li {
      display: flex;
      width: fit-content;
    }

    li button {
      color: $greySliderDot;
      text-indent: -100px;
      overflow: hidden;
      background: url('../../../assets/GrayDot.svg') center no-repeat;
      border: none;
      cursor: pointer;

      :nth-child(1) {
        display: none;
      }
    }

    li.slick-active button {
      color: $primary;
      text-indent: -100px;
      overflow: hidden;
      background: url('../../../assets/PurpleDot.svg') center no-repeat;
    }
  }

  @include breakpoint(desktop) {
    display: none !important; // to override default react-slick style
  }
}
