
.CustomSteps {

  :global {
     .ant-steps-item-title {
       color: transparent !important;
       width: 0;

       &:after {
         left: 0;
       }
     }
  }
}