@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.AgreementModalContainer {
  padding-top: 96px;

  @include breakpoint(desktop) {
    padding-top: 0;
  }

  :global(.ant-modal-content) {
    padding: 26px;

    @include breakpoint(desktop) {
      max-height: none;
    }
  }
}

.AgreementTitleText {
  color: $primaryDark;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}
