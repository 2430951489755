@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.input {
  padding: 16px;
}

.textArea {
  padding: 16px;
  resize: none !important;
}

