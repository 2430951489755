@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.AboutClinicWrapper {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.TabsContainer {
  width: 100%;
}

.ClinicSpecialtiesRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  @include breakpoint(desktop) {
    gap: 20px;
  }
}

.ClinicSpecialtiesSubRow {
  align-self: center;
  @include breakpoint(desktop) {
    align-self: flex-start;
    gap: 34px;
  }
}

.ServicesRow {
  gap: 10px;
  overflow: auto;
  flex-wrap: nowrap;
  max-width: 100%;
  @include breakpoint(desktop) {
    gap: 20px;
  }
}

.AboutRow {
  background: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 12px 12px 0;

  @include breakpoint(desktop) {
    padding: 30px 30px 6px;
  }

  :global(.ant-collapse > .ant-collapse-item) {
    border-top: 1px solid $border;
    padding: 12px 0;
  }

  :global(.ant-collapse > .ant-collapse-item):first-child {
    border-top: none;
    padding-top: 0;
  }
}

.DocumentsCollapse {
  width: 100%;

  :global(.ant-collapse-content-box.ant-collapse-content-box) {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.Title {
  font-weight: 600;
  font-size: 16px;
  margin-top: 5px;
  margin-left: 7px;

  @include breakpoint(desktop) {
    font-size: 18px;
    margin: 0;
  }
}

.SubTitle {
  width: 100%;
  :global(.ant-collapse-header-text) {
    font-weight: 600;
    font-size: 16px;
    @include breakpoint(desktop) {
      font-size: 18px;
    }
  }
  :global(.ant-collapse-header.ant-collapse-header) {
    padding: 0;
  }
}

.ClinicSpecialtiesTitle {
  display: none;
  @include breakpoint(desktop) {
    display: inline;
    font-size: 18px;
    font-weight: 600;
  }
}

.LinkRow {
  align-items: center;
  gap: 5px;
}

.ShieldIcon {
  max-width: 18px;
  path {
    stroke: $black;
  }
}

.LinkIcon {
  margin: 0 2px;
}

.LinkText.LinkText.LinkText {
  color: $primary;
}

.AboutClinicAgeToggle {
  justify-content: center;
}

.OthersClinicsMap.OthersClinicsMap.OthersClinicsMap {
  max-height: calc(100vh - 190px);
  height: 100%;
  display: flex;
  flex-shrink: 0;
  :global {
    .ymaps-2-1-79-map {
      max-height: calc(100% - 26px) !important;
      height: 100% !important;
    }
  }
}
