@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.MedcardBlock {
  width: 100%;
  min-height: 188px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: wrap;
  padding: 12px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

  @include breakpoint(desktop) {
    height: 260px;
    min-width: 270px;
    padding: 20px;
  }
}

.MedcardTitle {
  color: $primaryDark;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.ButtonsRow {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  flex-flow: nowrap;

  @include breakpoint(desktop) {
    margin-top: 16px;
  }
}

.ShowButton {
  width: 100%;
  height: 44px;

  @include breakpoint(desktop) {
    width: 122px;
  }
}

.SmallButton {
  width: 44px;
  height: 44px;
  padding: 8px;

  path {
    stroke: $primary;
  }
}

.CardUpdateInfo {
  font-size: 12px;
  line-height: 18px;
  color: $textGrey;
  margin-top: 4px;
}

.ProfileNameWrapper {
  display: flex;
  flex-direction: column;
  min-width: 100px;

  @include breakpoint(desktop) {
    width: 100%;
  }
}

.Avatar.Avatar.Avatar {
  width: 50px;
  height: 50px;
  cursor: pointer;

  @include breakpoint(desktop) {
    min-width: 50px;
    height: 50px;
  }
}

.SelectedAvatar {
  border: 3px solid $primary;
}

.MedcardProfile {
  margin-top: 14px;
  flex-wrap: nowrap;
  width: 100%;

  @include breakpoint(desktop) {
    margin-top: 19.22px;
    flex-direction: column-reverse;
    gap: 21px;
  }
}

.AvatarsWrapper {
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  align-items: flex-end;
  justify-content: flex-end;

  @include breakpoint(desktop) {
    justify-content: flex-start;
  }

  div:not(:first-child) {
    margin-left: -5px;
  }
}

.ImageReplaceText {
  font-size: 18px;
}
