@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ClinicContainer {
  width: 100%;
  background: transparent;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 36px;

  @include breakpoint(desktop) {
    gap: 20px;
    padding-top: 0;
  }
}

.BlockRow {
  width: 100%;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  @include breakpoint(desktop) {
    margin-top: 0;
    border-radius: 14px;
    flex-direction: row;
    padding: 23px;
    gap: 24px;
  }

  :global(.ant-carousel) {
    width: 100%;
    @include breakpoint(desktop) {
      max-width: 375px;
    }
  }
}

.InfoRow {
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.ScrollWrapper {
  width: 100%;
  @include breakpoint(desktop, max) {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    height: 410px;
  }
}

.ScrollWrapper::-webkit-scrollbar {
  display: none;
}

.ScrollWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.TitleRow {
  @include breakpoint(desktop, max) {
    position: fixed;
    top: 0;
    background: $background;
    z-index: 2;
    padding: 12px;
  }
}
