@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.StatisticsTabWrapper.StatisticsTabWrapper {
  width: 109px;
  margin-top: 30px;
  margin-bottom: 10px;

  img {
    max-width: 51px;
  }
}

.ProfileStatisticsBlock {
  margin-top: 0;
  gap: 10px;

  @include breakpoint(desktop) {
    margin-top: 20px;
  }
}

.InputsBlock {
  background: $white;
  border-radius: 14px;
  padding: 12px;
  gap: 14px;
  flex-direction: column;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);

  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  @include breakpoint(desktop) {
    padding: 30px;
    width: 850px;
  }
}

.FormTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;

  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.InputField {
  padding: 30px 16px 10px;
  height: 50px;
  border-color: $inputBorder;
}

.RadioLabel {
  margin-right: 12px;
}

.RadioGroupWrapper {
  align-items: center;

  :global(.ant-radio-wrapper) {
    margin-right: 12px;
  }
}

.AddProfileButton {
  max-width: 790px;
  height: 44px;
  width: 100%;
}

.AddProfileButtonWrapper {
  width: 100%;
  justify-content: center;

  @include breakpoint(desktop) {
    justify-content: flex-start;
  }
}

.CheckRow {
  align-self: flex-start;
  gap: 10px;
  flex-wrap: nowrap;
  margin-bottom: 14px;
  align-items: center;
  flex-direction: row;
}

.FormButtonsBlock {
  max-height: 98px;
  width: 100%;
  justify-content: center;
  margin-top: 14px;
  background: $white;
  padding: 12px;
  border-radius: 14px;
  gap: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);

  @include breakpoint(desktop) {
    width: 850px;
  }
}

.RemoveProfileLink {
  width: 100%;
}

.RemoveProfileLinkText.RemoveProfileLinkText {
  color: $primary;
  justify-content: center;
  margin: 0 auto;

  &:hover {
    color: $primary;
  }
}

.InputColumnContainer {
  width: 100%;
  gap: 14px;
  height: fit-content;
  margin-bottom: 14px;

  @include breakpoint(desktop) {
    width: 385px;
  }
}

.InputColumnWrapper {
  justify-content: space-between;
  width: 100%;
}

.InputRowContainer {
  gap: 14px;
  height: fit-content;
  margin-bottom: 14px;
  flex-direction: row;

  @include breakpoint(desktop) {
    flex-flow: nowrap;
  }
}

.InputFullNameColumn {
  flex-direction: column;
}

.EsiaBlock {
  display: flex;

  @include breakpoint(desktop) {
    width: 850px;
  }
}

.Modal :global(.ant-modal-content) {
  background-color: $background;

  @include breakpoint(desktop) {
    svg {
      circle {
        fill: $background;
      }
    }
  }
}

.ModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;

  @include breakpoint(desktop) {
    padding: 50px 80px;
  }

  .MilaMessageWrapper {
    width: 100%;
    justify-content: center;
    max-width: 347px;
    margin: 0 auto;

    @include breakpoint(desktop) {
      max-width: 480px;
    }

    .PrepRow {
      width: 100%;
    }
  }
}

.BottomMargin {
  margin-bottom: 28px;
}

.AvatarFormTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.AvatarFormWrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & :global {
    .ant-upload-list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
      border: none;
      padding: 0;

      &:before {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include breakpoint(desktop) {
    max-width: 480px;
  }
}

.AvatarFormText {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 130%;
}

.AvatarFormPrimaryText {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 130%;
  color: $primary;
  width: 100%;
}

.AvatarTextWrapper {
  padding: 10px 30px;
  justify-content: center;
}

.AvatarUploader.AvatarUploader.AvatarUploader {
  margin-top: 14px;
  :global(.ant-upload-select) {
    width: 100%;
  }

  :global(.ant-upload-select) {
    background-color: $white;
  }
}

.CancelButton,
.RemoveButton {
  width: 100%;
  height: 48px;
}

.SaveButton {
  width: 100%;
  height: 48px;
  margin-left: 14px;
}

.ButtonsWrapper {
  flex-wrap: nowrap;
  margin-top: 6px;
  width: 100%;
}

h3.ModalContentTitle {
  color: $primaryDark;
  font-weight: 600;
  font-size: 18px;
  margin: 0 auto 16px;

  @include breakpoint(desktop) {
    margin: 36px auto 16px;
    font-size: 24px;
  }
}

h4.ModalContentSubTitle {
  font-size: 16px;
  margin: 0 0 14px;
}

.Pict {
  max-width: 256px;
}

.WhiteCol {
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(desktop) {
    max-width: 480px;
  }

  & :global {
    .ant-form-item,
    .ant-row {
      width: 100%;
    }

    .ant-form-item {
      margin-bottom: 14px;
    }

    .ant-checkbox-wrapper {
      border-radius: 50px;
      border: 1px solid $border;
      min-height: 50px;
      padding: 10px 14px;
      line-height: 114.286%;
      display: flex;
      align-items: center;

      &:hover {
        background: $background;
      }
    }

    .ant-checkbox-wrapper-checked {
      background: $background;
    }

    .ant-checkbox {
      margin-top: 3px;
    }
  }
}

.CustomReason {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $border;
}

.FormItemWrapper {
  width: 100%;
}

.SaveProfileButtonWrapper {
  width: 100%;
}

.GosuslugiExtendedTextBlock {
  justify-content: center;
  margin: 0 auto;
}

.BoldText {
  font-size: 14px;
  font-weight: 600;
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
}

.UploadAvatar {
  margin: 14px auto;
  width: 102px;
  height: 102px;
  .RemoveAvatar {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: rgba($white, 0.45);
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        fill: $white;
      }
    }
  }

  &:hover .RemoveAvatar {
    opacity: 1;
  }
}

.TabsWrapper {
  flex-flow: nowrap;
}

.DesktopSliderRow {
  width: 100%;
  display: none;
  @include breakpoint(desktop) {
    display: flex;
    flex-wrap: nowrap;
  }
}

.MobileSliderRow {
  width: 100%;
  max-width: 375px;
  flex-wrap: nowrap;
  overflow: auto;

  @include breakpoint(desktop) {
    display: none;
  }
}

.Link {
  color: var(--primary-color) !important;
  
  &:hover {
    color: $textGrey !important;
  }
}
