@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Row {
  align-items: center;
  gap: 14px;
  flex-wrap: nowrap;
}

.Icon {
  min-width: 16px;
  max-width: 16px;
}
