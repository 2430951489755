@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ModalRowWrapper {
  background: transparent;
  align-items: center;
  padding: 36px 0px 64px;

  @include breakpoint(desktop) {
    padding: 36px 66px;
  }
}

.ModalRow {
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @include breakpoint(desktop) {
    min-width: 480px;
  }
}

.ModalTitle {
  font-weight: 600;
  font-size: 24px;
  color: $primaryDark;
  text-align: center;
}

.ImageRow {
  justify-content: center;
}

.ModalMessage {
  margin: 2px 0 6px;
}

.ButtonRow {
  position: relative;
  width: 100%;
}

.AddNewButton {
  min-height: 44px;
  width: 100%;
}

.ModalIcon {
  max-width: 306px;
}
