@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.MessageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
}

.MessageRow {
  flex-wrap: nowrap;
  gap: 10px;
  border: 1px solid $border;
  border-radius: 4px 10px 10px 10px;
  padding: 14px;
  box-shadow: 0 3px 5px rgba($black, 0.05);
  background-color: $white;
  margin-left: 10px;
  width: 100%;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.TitleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}

.Title {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include breakpoint(desktop) {
    font-size: 16px;
  }
}

.TextCol {
  width: 100%;
}

.Text {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0;

  span {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $primary;
    white-space: nowrap;
  }
}

.ViewedText {
  color: $textGrey;
}

.TextLink.TextLink {
  color: $primary;
}

.NotificationsWrapper {
  width: 100%;
  gap: 14px;
}

.TimeWithMarkContainer {
  align-items: center;
  width: 100%;

  svg {
    margin-left: 10px;
  }
}

.MessageTag {
  align-self: flex-end;
  white-space: nowrap;
}

.NotificationsTabNumber {
  background: $primary;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 8px;
  justify-content: center;
  align-items: center;

  span {
    color: $white;
    font-size: 10px;
  }
}

.NotificationsTabNumberInactive {
  background: $greyBackground;
}

.HiddenLabel {
  display: none;
}

.ScrollableDiv {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.Chats {
  opacity: 0;
  position: absolute;
  z-index: -10;
  margin-top: 28px;
  width: 100%;

  &.ShowChats {
    position: relative;
    opacity: 100;
    z-index: 1;
  }

  @media (max-width: 920px) {
    min-height: 67vh !important;
  }

  & :global {
    .chat-container.chat-container {
      border-radius: 14px;
      background: #fff;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
      overflow: hidden;
      font-family: 'Inter', sans-serif;
      color: $textPrimary;

      & svg path {
        fill: $chatsSvg;
      }

      & svg.chat-settings circle {
        fill: $primary;
      }

      & .chat-send-btn {
        display: inline-block;
        position: relative;
        cursor: pointer;
      }

      & .chat-send-btn svg path {
        fill: $primary;
      }

      & .chat-send-btn.chat-send-btn_disabled svg path {
        fill: $chatsSvg;
      }

      & .message-box-main-container {
        flex-direction: row;
        justify-content: flex-end;
      }

      & .message-box-text {
        line-height: 18px !important;
        background: $background !important;
        border: none !important;
        border-radius: 2px 6px 6px 6px;
        padding: 17px 17px 30px 17px;
        width: fit-content;
      }

      & .message-wrapper.reverse .message-box-text {
        border-radius: 6px 2px 6px 6px;
        background: #ffffff !important;
        border: 1px solid #dadbe1 !important;
        color: $textPrimary;
        padding: 17px 17px 30px 17px;
        width: fit-content;
      }

      & .message-wrapper.reverse .message-box-text {
        border-color: $inputBorder;

        &:before {
          border-color: transparent transparent $inputBorder transparent;
        }
      }

      & .message-box-settings-container .message-box-settings-item:not(:first-child) {
        display: none;
      }

      & .message-box-settings-container .message-box-settings-item {
        margin-bottom: 0;
      }

      & .message-box-settings-container .message-box-settings-item:hover {
        color: $primary;

        & svg path {
          fill: $primary;
        }
      }

      & .message-box-settings-item.outer-click-btn {
        &:first-child {
          color: $primary;
        }

        &:last-child {
          color: $red;
        }
      }

      & .message-box-text {
        color: $textPrimary;
        font-size: 14px;
        line-height: 16px;
      }

      & .message-box-author {
        font-size: 16px;
        color: $textPrimary;
      }

      & .message-attachment {
        color: #15181f;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      & .chat-main {
        max-height: 670px;
      }

      & .chat-wrapper {
        display: flex;
        flex-direction: column;
      }

      & .chat-date {
        border-radius: 14px;
        padding: 2px 10px;
        border: 1px solid #d9d9d9;
        display: inline-flex;
        margin: 0 auto 8px;
        color: #15181f;
        min-height: 20px;
        min-width: 80px;
      }

      & .message-read-icon {
        top: 24px;
        right: 20px;
      }

      & .message-read-icon_merge-messages {
        top: 8px !important;
        right: 20px;
      }

      & .message-read-icon svg {
        width: 7.2px;
      }

      & .chat-send-btn svg path {
        fill: $primary !important;
      }

      & .chat-send-btn.chat-send-btn_disabled svg path {
        fill: $chatsSvg;
      }

      & .message-box-text {
        border: none;
        font-size: 14px;
        line-height: 16px;
        background: #ffffff;
      }

      & .message-box-text .message-box-time {
        bottom: 10px;
        right: auto;
        top: auto;
        font-size: 10px;
      }

      & .message-box-text::after {
        display: none;
      }

      & .message-box-text::before {
        display: none;
      }

      & .message-box-author {
        font-size: 16px;
        color: $primary;
      }

      & .chat-input-container {
        align-items: center;
      }

      & .chat-input-container,
      .chat-item_selected,
      .chat-item:hover {
        background-color: white;
      }

      @include breakpoint(desktop) {
        & .chat-item_selected {
          background-color: $background !important;
        }
      }

      & .chat-input {
        height: 26px;
      }

      & .chat-item__name {
        font-size: 14px;
      }

      & .chat-item__user-name {
        font-size: 12px;
      }

      & .chat-item__name,
      .chat-item__user-name {
        color: $textPrimary;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & .chat-item__text {
        font-size: 12px;
        color: $textGrey;
      }
    }

    .chat-name.chat-name {
      font-size: 18px;
      color: $textPrimary;

      & svg {
        display: none;
      }
    }
  }
}

.ChatsList {
  margin-top: 20px;
}

.Image {
  max-width: 217px;
}

.StubContainer {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.MilaIcon {
  max-width: 30px;
}
