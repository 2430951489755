@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.ErrorPageContainer {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @include breakpoint(desktop) {
    gap: 20px;
  }
}

.ErrorPageTitle {
  font-size: 16px;
  font-weight: 600;
}

.ErrorPageDescription {
  text-align: center;
  max-width: 170px;
  color: $textGrey;
  @include breakpoint(desktop) {
    max-width: 330px;
  }
}

.ErrorPageText {
  color: $textGrey;
  @include breakpoint(desktop) {
    max-width: 227px;
    text-align: center;
  }
}

.ErrorPageImage {
  max-width: 260px;
}

.ErrorPageLink.ErrorPageLink {
  color: $primary;
}

.ErrorButton {
  width: 100%;
  height: 44px;
  @include breakpoint(desktop) {
    width: 310px;
  }
}
