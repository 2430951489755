@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.FormItemWrapper {
  :global {
    .ant-input-affix-wrapper {
      height: 50px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      border: 1px solid $inputBorder;

      input {
        top: auto;
      }
    }

    .ant-input.ant-input {
      font-size: 14px;
      line-height: 16px;
      color: $textPrimary;
      padding: 0;
      top: 8px;
    }

    .ant-input-clear-icon {
      display: flex;
      align-items: center;
    }
  }
}

.popupContent {
  color: $textGrey;
  font-size: 11px;
}
