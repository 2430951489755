@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.CustomTag {
  color: $primary;
  border-radius: 6px;
  background-color: $background;
  padding: 8px 10px 8px 10px;
  margin: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border: none;
}

button.Active.Active.Active.Active {
  background-color: $primary;
  color: $white;
}
