@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.RecordCardsContainer {
  display: flex;
  flex-direction: column;
}

.CalendarCardTitleWrapper {
  margin-bottom: 14px;
  margin-top: 30px;
}

.CalendarCardTitle {
  font-size: 18px;
  color: $primaryDark;
  line-height: 24px;
  font-weight: 600;
  align-self: center;
}

.SameDateRecordsContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.RecordsScroll {
  overflow-x: hidden !important;
}
