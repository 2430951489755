@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.frame {
  background-color: $white;
  width: 100%;
  border: 1px solid $border;
  border-radius: 20px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  :global {
    .ant-image {
      width: 55px;
      height: 55px;
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $background;
      border-radius: 50%;
    }
  }
}

.button {
  width: 100%;
  border: none;
  height: 44px;
  margin-bottom: 14px;
}

.text {
  font-size: 12px;
  line-height: 133%;
  text-align: center;
  color: $textGrey;
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
}
