@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ProfileRow {
  background: $lightBlue;
  box-shadow: 0 1px 5px rgba($black, 0.05);
  border-radius: 14px;
  padding: 15px 30px;
  width: 100%;
}

.DoctorFioText.DoctorFioText {
  color: $textPrimary;
}

.DoctorRoleText.DoctorRoleText {
  color: rgba($textPrimary, 0.7);
}
