@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.AddProfile {
  border-radius: 50%;
  border: 1px solid rgba($textGrey, 0.3);
  min-width: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  svg {
    width: 25px;
    height: 25px;
    path {
      stroke: $primary;
      stroke-width: 1;
    }
  }
}

.Avatar.Avatar {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;

  article {
    font-size: 18px;
  }
}

.ProfilesRow {
  gap: 16px;
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;

  @include breakpoint(desktop) {
    display: none;
  }
}

.Circle {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $white;
  border-radius: 50%;

  path {
    &:first-child {
      stroke: $white;
    }
  }
}

.ProfileLink {
  text-align: center;
  max-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UserName {
  color: $textPrimary;
  font-size: 11px;
  line-height: 16px;
  width: 64px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.PlusIcon.PlusIcon {
  width: 20px;
  height: 20px;
  margin-top: 4px;
}

.AvatarWrapper {
  position: relative;
  text-align: center;
  max-width: 50px;
}
