@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.FormItemWrapper {
  :global {
    .ant-input-affix-wrapper {
      height: 50px;
      padding: 8px 16px;
      display: flex;
      align-items: center;

      input {
        top: auto;
      }
    }

    .ant-input.ant-input {
      font-size: 14px;
      line-height: 16px;
      color: $textPrimary;
      padding: 0;
      top: 8px;
    }

    .ant-select-clear {
      display: flex;
      align-items: center;
      inset-inline-end: 40px;
      scale: 1.4;
      z-index: 10;

      @include breakpoint(desktop) {
        z-index: 1;
      }
    }
  }
}
