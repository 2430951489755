@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
  cursor: pointer;
}

.TabsLabels {
  :global(.ant-tabs-tab-btn) {
    font-size: 12px;
    @include breakpoint(desktop) {
      font-size: 14px;
    }
  }
}

.ButtonsRow {
  justify-content: space-between;
  @include breakpoint(desktop) {
    background-color: transparent;
    justify-content: space-between;
  }
}

.ButtonCol {
  display: flex;
  flex-direction: column;
  max-width: 76px;
  align-items: center;
  @include breakpoint(desktop) {
    flex-direction: row;
    max-width: none;
    gap: 8px;
  }
}

.Button {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  @include breakpoint(desktop) {
    background-color: $white;
  }
}

.LoaderRow {
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.LoaderTitle {
  font-weight: 600;
}

.LoaderText {
  font-size: 12px;
  color: $textGrey;
}

.LinkButton {
  display: flex;
  align-items: center;
  flex-direction: column;
  
  @include breakpoint(desktop) {
    flex-direction: row;
    gap: 8px;
  }
}
