@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ImageReplace {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  width: 36px;
  height: 36px;
}

.ImageReplaceText {
  color: $textPrimary;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.28px;
}
