@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.BlockWrapper {
  width: 100%;
  height: 124px;
  padding: 20px 30px;

  flex-wrap: nowrap;
  gap: 20px;
  display: none;

  @include breakpoint(desktop) {
    display: flex;
  }
}

.GosImage.GosImage {
  width: 80px;
  height: 80px;
}

.BlockText {
  font-size: 12px;
  color: $textGrey;
}

.BlockTitle {
  font-size: 14px;
  font-weight: 600;
}

.GosButton {
  height: 44px;
  width: 239px;
}

.BlockTextContainer {
  gap: 14px;
  min-width: 413px;
}
