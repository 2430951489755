@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DoctorEducationWrapper {
  width: 100%;
  background: transparent;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.EducationCol {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include breakpoint(desktop) {
    width: 100%;
    li > div:nth-child(2) {
      margin-top: 6px;
    }
  }
}

.Title {
  font-weight: 600;
  font-size: 16px;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.SubTitle {
  width: 100%;
  :global(.ant-collapse-header-text) {
    font-weight: 600;
    font-size: 16px;
  }
  :global(.ant-collapse-header.ant-collapse-header) {
    padding: 0;
  }
}

.DocsContainer {
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.Year {
  font-weight: 600;
  font-size: 14px;

  @include breakpoint(desktop) {
    font-size: 24px;
  }
}

.EducationYearCol {
  white-space: nowrap;
}

.Description {
  font-weight: 400;
  font-size: 14px;
  color: $textGrey;
  @include breakpoint(desktop) {
    max-width: 435px;
    min-width: 435px;
  }
}

.Type {
  font-weight: 400;
  font-size: 14px;
  @include breakpoint(desktop) {
    font-size: 16px;
  }
}

.EducationDescriptionRow {
  gap: 8px;
  @include breakpoint(desktop) {
    flex-wrap: nowrap;
    gap: 30px;
    align-items: flex-start;
  }
}

.EducationDescriptionCol {
  max-width: 220px;
  @include breakpoint(desktop) {
    max-width: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    align-items: flex-start;
    margin-top: 3px;
  }
}

.DoctorEducationWrapper :global(.ant-timeline .ant-timeline-item:last-child) {
  padding-bottom: 0;
}

.EducationRow :global(div.ant-timeline-item-head) {
  border-width: 2px;
  border-color: $orange;
}

.DocumentsCollapse {
  margin-top: 20px;
  width: 100%;
}
