@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Icon.Icon.Icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.ColoredIcon {
  min-width: 32px;
  height: 32px;
  border-radius: 6px;
}
