@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PatientListContainer {
  background-color: $white;
  min-height: auto;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 14px;
  flex-grow: unset;
  @include breakpoint(desktop) {
    box-shadow: none;
  }
}

.Title {
  font-weight: 600;
  font-size: 16px;
}

.AddButton {
  height: 44px;
  width: 100%;
}

.ButtonContent {
  align-items: center;
  gap: 10px;
}

.RadioGroup {
  width: 100%;
}

.Radio {
  width: 100%;
}

.OptionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px solid $border;
  border-radius: 8px;
  width: 100%;
  padding: 8px 16px;
}

.OptionCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;

  & span:nth-child(2) {
    width: 100%;
    padding: 0px;
  }

  :global(.ant-radio) {
    position: absolute;
    right: 15px;
    min-width: 16px;
  }
}

.Avatar.Avatar.Avatar {
  width: 26px;
  height: 26px;
}

.AvatarText {
  font-size: 12px;
}
