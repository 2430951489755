@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Title {
  color: $textPrimary;
  font-size: 16px;
  font-weight: 600;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.Row {
  gap: 8px;
}

.RegionRow {
  width: 100%;
  justify-content: space-between;
}

.SearchRow {
  padding: 12px;
  border-radius: 14px;
  background-color: $white;
}
