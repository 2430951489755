@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.NameRow {
  align-items: flex-start;
  gap: 14px;
}

.DocIcon {
  min-width: 16px;
  max-width: 16px;
  margin-top: 6px;
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
}

.DisabledLink {
  cursor: default;
}
