@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.WhiteRow {
  background-color: $white;
  padding: 12px;
  border-radius: 14px;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  @include breakpoint(desktop) {
    padding: 30px;
  }
}
