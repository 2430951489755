@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DoctorsBlockWrapper {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.Title {
  font-size: 16px;
  font-weight: 600;
  display: none;
  @include breakpoint(desktop) {
    display: block;
  }
}

.AgeContainer {
  width: 238px;
  align-self: center;

  @include breakpoint(desktop) {
    align-self: flex-start;
  }
}

.DoctorsAgeToggleRow {
  align-items: center;
  justify-content: center;
  gap: 34px;
  @include breakpoint(desktop) {
    justify-content: flex-start;
  }
}
