@import 'src/app/theme.scss';

.PrimalButton {
  border-radius: 10px;
  background: $primaryButton;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  :global(.ant-typography) {
    color: $white;
  }

  &:hover {
    background-color: rgba($primaryButton, 0.08);
    border: 1px solid;

    :global(.ant-typography) {
      color: $primaryButtonText;
    }

    path {
      stroke: $primaryButtonText;
    }
  }

  &.labQuestPrimary {
    background: $primaryButton;
    path {
      stroke: $primaryButtonText !important;
    }
    :global(.ant-typography) {
      color: $primaryButtonText;
    }
    &:hover {
      background-color: rgba($primaryButton, 0.08);
      :global(.ant-typography) {
        color: $primaryButtonText;
      }
      path {
        stroke: $primaryButtonText !important;
      }
    }
  }

  &:disabled {
    background-color: $disbledButton;
    :global(.ant-typography) {
      color: $disbledButtonText;
    }
  }
}

.SecondaryButton {
  border-radius: 10px;
  background: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-size: 16px;
  border: none;

  :global(.ant-typography) {
    color: $primary;
  }
}

.SecondaryButton:hover {
  background-color: $hoveredBackground;
}
