@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.SearchWrapper {
  width: 100%;
  background-color: $white;
  min-height: auto;
  gap: 14px;
}

.ScrollWrapper {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  height: 344px;
}

.ScrollWrapper::-webkit-scrollbar {
  display: none;
}

.ScrollWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.TitleRow {
  display: flex;
  align-items: center;
  justify-content: start;
}

.SearchTitle {
  font-weight: 700;
  font-size: 14px;
}

.SearchWrapper :global(.ant-list) {
  box-shadow: none;
  border-block-start: 1px solid rgba(5, 5, 5, 0.06);
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}

// Doctor/Clinic List

.ListContainer {
  width: 100%;
  padding-top: 0;
  min-height: auto;
}

.TextCol {
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.SearchRow {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.Text {
  font-size: 14px;
  font-weight: 400;
}

.SmallText {
  font-size: 12px;
  font-weight: 400;
}

img.DoctorPhoto.DoctorPhoto {
  width: 26px;
  height: 26px;
}

.TagRow {
  gap: 5px;
}

.Tag {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $textGrey;
}

.SearchItem.SearchItem.SearchItem {
  cursor: pointer;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 10px;
}

.SearchItem:hover {
  background-color: rgba($primary, 0.12);
}

.SearchItem:active {
  background-color: rgba($primary, 0.24);
}

.ImagePreview {
  max-width: 40px;
  max-height: 40px;
}

.PromoSearch {
  :global {
    .ant-image-img {
      max-width: 95px;
      max-height: 64px;
      margin-top: 5px;
    }

    .ant-typography {
      font-size: 12px;
    }
  }
}

.Icon {
  min-width: 10px;
}
