@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ProfileRow {
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  flex-shrink: 0;

  :global(.ant-image-error) {
    img {
      display: none;
    }

    &:after {
      display: block;
      content: '';
      background: $background url('../../../assets/User.svg') no-repeat center;
      border-radius: 50%;
      object-fit: contain;
      width: 26px;
      height: 26px;
    }
  }

  @include breakpoint(desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.SubRow {
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;

  .Avatar {
    background: $background;
    object-fit: contain;
    width: 26px;
    height: 26px;
  }

  .Avatar.AvatarPatient {
    width: 36px;
    height: 36px;
  }
}

.DoctorFioText {
  font-size: 16px;
  cursor: pointer;

  @include breakpoint(desktop) {
    white-space: nowrap;
  }
}

.Caret {
  margin-left: 4px;
}

.FioText {
  font-size: 16px;
  font-weight: 600;

  @include breakpoint(desktop) {
    white-space: nowrap;
  }
}

.RoleText {
  font-size: 12px;
  font-weight: 400;
  color: $textGrey;
}
