@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.CardContainer {
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  @include breakpoint(desktop) {
    padding: 20px 30px;
  }
}

.Divider {
  margin: 0;
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  line-height: 16px;
  text-align: start;
}

.TitleText {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.CardTextBlock {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.TitleRow {
  justify-content: space-between;
  width: 100%;
}

.CardContent {
  flex-direction: column;
  gap: 6px;
}

.ServiceDescriptionText {
  line-height: 130%;
}

.TagWrapper {
  background: transparent;
  display: flex;
  border-radius: 14px;
  width: fit-content;
  padding: 2px 10px;
  align-items: center;
}

.TagRedBorder {
  border-color: $red;
}

.TagGreenBorder {
  border-color: $green;
}

.TagIcon {
  margin-right: 6px;
}

.RedText {
  span {
    color: $red;
  }
}
