@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ClinicCatalogWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.MilaMessage {
  @include breakpoint(desktop) {
    display: none;
  }
}

.EmptyListRow {
  flex-direction: column;
  gap: 20px;
  align-content: center;
}

.ImageRow {
  justify-content: center;
}

.Text {
  text-align: center;
}

.Icon {
  max-width: 257px;
}
