@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.TabContainer {
  display: flex;
  padding: 1px;
  width: 100%;

  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: visible;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid $lightBlue;
    }

    .ant-tabs {
      width: 100%;
    }
  }
}

div.TabContainer :global(div.ant-tabs-tab-disabled.ant-tabs-tab-disabled) {
  cursor: not-allowed;
}

div.TabContainer.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab) {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  border-color: rgba($primary, 0.5);
  padding: 8px 0;
}

.TabContainer :global(.ant-tabs-nav),
.TabContainer :global(.ant-tabs-nav-wrap),
.TabContainer :global(.ant-tabs-nav-list) {
  width: 100%;
  margin: 0;
}

div.TabContainer.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab-active) {
  color: $textPrimary;
}

div.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):hover,
div.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):active {
  color: $primary;
}

:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn.ant-tabs-tab-btn) {
  color: $textPrimary;
}
