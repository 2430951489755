@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.ScheduleWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  @include breakpoint(desktop) {
    width: 494px;
    border: 1px solid $border;
    border-radius: 14px;
    box-shadow: 3px 3px 5px rgba(21, 24, 31, 0.05);
    padding: 22px;
  }
}

.TabsContainer {
  width: 100%;
}

.Tabs {
  width: 100%;

  :global(.ant-tabs-tab) {
    width: 30%;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 12px;
  }
}

.Select.Select.Select {
  width: 100%;

  :global(.ant-select-arrow) {
    color: $textPrimary;
    top: 28%;
  }

  :global(div.ant-select-selector) {
    padding: 16px;
    height: auto;
  }

  :global(.ant-select-selection-item) {
    line-height: 0;
  }

  .Option {
    display: flex;
    align-items: center;
  }
}

.OptionRow.OptionRow {
  word-break: break-word;
  white-space: break-spaces;
  display: flex;
  flex-direction: column;
}

.OptionAddress {
  color: $textGrey;
  font-size: 12px;
}

.Calendar {
  path {
    stroke: $primary;
  }
}

.PriceText {
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  color: $textPrimary;
  background-color: rgba($orange, 0.2);
  border-radius: 40px;
  padding: 1px 10px;
}

.HiddenDatePicker {
  visibility: hidden;
  position: absolute;
  right: -50px;
  width: 0;
  padding: 0;
  @include breakpoint(desktop) {
    position: relative;
  }
}

.CentredDatePicker {
  right: 0;
  left: -50px
}

.DateRow {
  gap: 8px;
}

.TimeRow {
  gap: 8px;
}

.TimeTag {
  height: 36px;
  width: 65px;
  @include breakpoint(desktop) {
    width: 70px;
  }
}

.PaginationButtons {
  display: flex;
  justify-content: space-between;
  height: 36px;
  width: 70px;
}

.CaretTag.CaretTag {
  height: 36px;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: rgba($primary, 0.12);
  margin: 2px;
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    border: 1px solid $border;
    background-color: transparent;
    cursor: no-drop;
  }

  path {
    stroke: $primary;
  }
}

.WaitingBlock {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.WaitingText {
  text-align: center;
  font-size: 12px;
}

.WaitingButton {
  height: 40px;
  width: 164px;
}

.CaretLeft {
  transform: rotate(180deg);
}

.ModalContainer {
  @include breakpoint(desktop) {
    max-height: 366px;
  }
}

.CancelRecordWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .MilaMessageWrapper {
    width: 100%;
    justify-content: center;
    max-width: 347px;
    margin: 0 auto;
    margin-top: 6px;

    @include breakpoint(desktop) {
      max-width: 400px;
    }
  }
}

.NoSlotsHint {
  text-align: center;
  color: $textGrey;
}
