@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.YouChoseContainer {
  background-color: $white;
  min-height: auto;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 14px;
  flex-grow: unset;
  width: 100%;
  height: fit-content;

  @include breakpoint(desktop) {
    border: 1px solid $border;
    box-shadow: 2px 3px 5px rgba(21, 24, 31, 0.05);
    padding: 20px;
    min-width: 385px;
  }
}

.DocAvatar {
  max-width: 26px;
  max-height: 26px;
  margin-top: 2px;
}

.Title {
  font-weight: 600;
  font-size: 16px;
}

.FullName {
  font-size: 14px;
}

.Description {
  font-size: 12px;
  color: $textGrey;
}

.PaleLink.PaleLink.PaleLink {
  font-weight: 300;
  font-size: 11px;
  color: $textGrey;
  display: inline;
  text-decoration: underline;
}

.TextPaid {
  font-weight: 400;
  font-size: 12px;
  color: $green;
  gap: 5px;
}

.TextSmall {
  font-size: 12px;
}

.Divider {
  margin: 0;
}

.Price {
  font-weight: 400;
  font-size: 14px;
  color: $textPrimary;
  background-color: rgba($orange, 0.2);
  border-radius: 40px;
  padding: 4px 10px;
  max-width: fit-content;
}

.Icon {
  width: 26px;
  max-height: 20px;
  margin-top: 2px;

  path {
    stroke: $black;
  }
}

.Row {
  gap: 10px;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.Col {
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-width: 290px;
  @include breakpoint(desktop) {
    max-width: unset;
  }
}

.Ruble {
  margin-left: 2px;
}

.Payment {
  width: fit-content;
}

.ClickableBlockRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ClickableBlock {
  margin-top: 16px;
  padding: 13px;
  height: 98px;
  width: 100px;
  border-radius: 14px;
  border: 1px solid $border;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: $primary;
    border-color: $primary;
    border: 1px solid;
    background-color: rgba($primary, 0.08);
  }

  article {
    font-size: 12px;
    max-width: 74px;
    text-align: center;
    white-space: break-spaces;
    line-height: 16px;
  }
}
