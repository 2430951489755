@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.CalendarCardWrapper {
  width: 100%;
  display: inline-block;
  background: $white;
  border-radius: 14px;
  padding: 12px;
  gap: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);

  :global(.ant-divider-horizontal) {
    margin: 4px 0;
  }

  @include breakpoint(desktop) {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &:hover {
    box-shadow: 0 1px 5px rgba($black, 0.05);
    background-color: $hoveredBackground;
  }
}

.ServiceName {
  font-size: 16px;
  font-weight: 600;

  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.Time {
  font-size: 12px;
  color: $black;
  min-width: 50px !important;
  width: 50px !important;
  word-break: keep-all;

  @include breakpoint(desktop) {
    font-size: 18px;
    margin-right: 10px;
  }
}

.DateRow {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 14px;
  flex-wrap: nowrap;

  @include breakpoint(desktop) {
    margin-bottom: 0;
  }

  &:hover {
    .ServiceName, .Time {
      color: $primary;
    }
  }
}

.ClinicBlockRow {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: nowrap;
  gap: 10px;

  @include breakpoint(desktop) {
    width: 513px;
    justify-content: flex-start;
    gap: 14px;

    button {
      margin-left: 16px;
    }
  }
}

.ClinicNameBlock {
  max-width: 80%;
  margin-right: auto;

  @include breakpoint(desktop) {
    margin-right: 0;
  }
}

.AddressText {
  color: $textGrey;
  font-size: 12px;
}

.PatientAvatarContainer {
  margin-right: 10px;
}

.Avatar.Avatar {
  background: $background;
  border-radius: 50%;
  object-fit: contain;
  width: 36px;
  height: 36px;
}

.TimeLeftText {
  color: $textGrey;
  margin: 0 auto;
}

.TimeLeftRow {
  margin-top: 16px;
  width: auto;

  @include breakpoint(desktop) {
    width: 277px;
  }
}

.CardContentContainer {
  margin-top: 14px;
  @include breakpoint(desktop) {
    flex-wrap: nowrap;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;
  }
}

.ServiceNameAndTime {
  @include breakpoint(desktop) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.ClinicIcon {
  align-self: flex-start;
  margin-top: 5px;
}

.ProfileCol {
  max-width: 430px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  :global {
    .ant-row {
      @include breakpoint(desktop) {
        margin-left: 0;
      }
    }
  }
}
