@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Switch.Switch {
  padding: 5px 38px 5px 10px;
  height: auto;
  display: flex;
  align-items: center;
  background-color: $background;
  color: $textPrimary;
  border: 1px solid $border;

  &:hover {
    background-color: $background;
  }

  @include breakpoint(desktop) {
    &:hover {
      background-color: $white;

      :global {
        .ant-switch-handle:before {
          background: $green url('../../../assets/OkIcon.svg') center no-repeat;
        }
      }
    }
  }

  &:global(.ant-switch-disabled:not(.ant-switch-checked)):hover {
    background-color: $background;

    :global {
      .ant-switch-handle:before {
        background: $white;
      }
    }
  }

  &:global(.ant-switch-disabled) {
    border-color: $disabledInput;

    :global {
      .ant-switch-handle:before {
        background: url('../../../assets/OkIcon.svg') center no-repeat;
        background-color: $disabledSwitchBg;
      }
    }
  }

  &:global(.ant-switch-checked:not(.ant-switch-disabled)) {
    border-color: $green;
    background: $white;
    &:hover {
      background: $background;
    }

    :global {
      .ant-switch-handle:before {
        background: $green url('../../../assets/OkIcon.svg') center no-repeat;
      }
    }
  }

  :global {
    .ant-switch-inner {
      display: flex;
      align-items: center;
      padding: 0;
      .ant-switch-inner-unchecked {
        display: none;
      }
      .ant-switch-inner-checked {
        margin-inline-start: 0;
        margin-inline-end: 0;
      }
    }

    .ant-typography {
      font-size: 12px;
      line-height: 183%;
      letter-spacing: -0.02em;
    }

    .ant-switch-handle {
      right: 6px;
      left: auto;
      top: 50%;
      width: 22px;
      height: 22px;
      transform: translateY(-50%);

      &:before {
        border-radius: 50%;
        width: 22px;
        height: 22px;
        left: 0 !important;
        right: 0 !important;
      }
    }
  }
}
