@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PaymentCardsWrapper {
  width: 100%;
  gap: 14px;
}

.ScrollableDiv {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.PaymentsStubRow {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  text-align: center;
}

.PaymentsStubImage {
  max-width: 292px;
}

.PaymentsStubTitle {
  font-weight: 600;
  font-size: 16px;
  max-width: 309px;
}

.PaymentsStubText {
  font-size: 14px;
  color: $textGrey;
  max-width: 509px;
}

.PaymentsStubButtonContent {
  align-items: center;
  gap: 13px;
  flex-wrap: nowrap;

  path {
    stroke: $white;
  }
}

.PaymentsStubButton {
  height: 44px;
}
