@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.RecomendationRow {
  max-width: 396px;
  flex-direction: column;
  gap: 14px;
  align-items: flex-start;
  padding: 16px;
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
}

.Row {
  align-items: center;
  gap: 14px;
  flex-wrap: nowrap;
}

.MedicamentsRow {
  border-radius: 40px;
  border: 1px solid $buttonBorder;
  padding: 4px 10px;
}

.Button {
  width: 100%;
  height: 36px;
}

.SmallText {
  font-size: 12px;
}

.Icon {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}

.Link {
  width: 100%;
}
