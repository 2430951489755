@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PromoImage.PromoImage.PromoImage {
  width: 80px;
  height: 56px;
  border-radius: 8px;
  object-fit: cover;

  @include breakpoint(desktop) {
    height: 160px;
    width: 240px;
  }
}

.PromoRow {
  flex-wrap: nowrap;
  gap: 10px;
  border-bottom: 1px solid $disabledInput;
  padding-bottom: 20px;

  @include breakpoint(desktop) {
    gap: 23px;
    padding-bottom: 30px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.TextCol {
  gap: 2px;
  display: flex;
  flex-direction: column;
  @include breakpoint(desktop) {
    gap: 10px;
  }
}

.LocationRow {
  align-items: center;
  gap: 10px;
  margin-top: 6px;
  @include breakpoint(desktop) {
    margin-top: 0px;
  }
}

.Title {
  font-size: 14px;
  line-height: 18px;

  @include breakpoint(desktop) {
    font-size: 18px;
    font-weight: 600;
  }
}

.Text {
  font-size: 12px;
  @include breakpoint(desktop) {
    font-size: 14px;
  }
}

.DescText {
  font-size: 14px;
  display: none;
  @include breakpoint(desktop) {
    display: inline;
  }
}

.GreyText {
  font-size: 12px;
  color: $textGrey;
}

.Caret {
  margin-left: 7px;
}

.Location {
  display: none;
  @include breakpoint(desktop) {
    display: inline;
  }
}
