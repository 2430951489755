@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Title {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before {
    content: '';
    background: url('../../../assets/Location.svg') center no-repeat;
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }

  &::after {
    content: '';
    background: url('../../../assets/Chevron.svg') center no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 2px;
  }
}

.Card {
  align-items: center;
  background: transparent;
  padding: 10px 0;
  overflow: hidden;

  :global {
    .ant-select,
    .ant-row {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .CardTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    color: $primaryDark;
    margin-bottom: 20px;

    @include breakpoint(desktop) {
      font-size: 24px;
    }
  }

  .ChosenCity {
    justify-content: space-between;
    font-weight: 600;
    border-bottom: 1px solid $border;
    cursor: pointer;
    padding: 20px 5px;

    &:after {
      content: '';
      background: url('../../../assets/OkIconPurple.svg') center no-repeat;
      width: 11px;
      height: 8px;
    }

    @include breakpoint(desktop) {
      justify-content: center;
      padding: 6px 0;
      border: none;
      margin: 0 0 2px;

      &:hover {
        background: $background;
        border-radius: 6px;
      }

      &:after {
        content: none;
      }

      &:before {
        content: '';
        background: url('../../../assets/OkIconPurple.svg') center no-repeat;
        width: 11px;
        height: 8px;
        margin-right: 12px;
      }
    }
  }

  .City {
    margin: 12px 0;
    padding: 8px 5px;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
      background: $background;
      border-radius: 6px;
    }

    @include breakpoint(desktop) {
      justify-content: center;
      padding: 6px 0;
      margin: 0 0 2px;
    }
  }
}

.Search {
  width: 100%;
  max-width: 400px;

  @include breakpoint(desktop) {
    justify-content: center;
    padding: 6px 0;
    margin: 0 0 12px;
  }
}

.Cities {
  overflow-y: auto;
  max-height: calc(100vh - 300px);
}

.Title {
  color: $textPrimary;
  font-weight: 600;
  font-size: 16px;

  @include breakpoint(desktop) {
    font-size: 18px;
  }
}
