@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.FormItemWrapper {
  :global {
    .ant-picker {
      height: 50px;
      padding: 8px 16px;
      width: 100%;
    }
    .ant-picker-input {
      input {
        font-size: 14px;
        top: 8px;
      }
      input:placeholder-shown {
        visibility: hidden;
      }
    }
  }
}

:global {
  .ant-picker-month-btn, .ant-picker-year-btn {
    color: $primary !important;
  }
}
