@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.TopRow {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row !important;
  width: 100%;
  max-height: 70px;
  @include breakpoint(desktop) {
    justify-content: flex-start;
    width: 850px;
  }
}

.Title {
  font-size: 18px;
  font-weight: 600;
  color: $primaryDark;
  text-align: start;
  @include breakpoint(desktop) {
    font-size: 30px;
    text-align: start;
    padding-left: 0;
  }
}

.BackButtonDesktop {
  display: none;
  cursor: pointer;
  margin-right: 20px;
  @include breakpoint(desktop) {
    display: block;
  }
}

.BackButtonMobileCol {
  cursor: pointer;
  width: 50px;
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.AddNewText {
  display: none;
  @include breakpoint(desktop) {
    display: inline;
  }
}

.AddNewButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  padding: 0;

  @include breakpoint(desktop) {
    width: auto;
    height: 44px;
    padding: 0 22px;
  }
}

.AddButtonRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.PlusIcon {
  min-width: 16px;
  path {
    stroke: $white;
  }
}
