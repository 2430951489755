@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import '/src/app/theme.scss';

body {
  margin: 0;
  background-color: $background;
}

.rpv-core__page-layer::after {
  box-shadow: none !important;
}
