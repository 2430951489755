@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Title {
  font-size: 18px;
  font-weight: 600;
  color: $primaryDark;
  text-align: center;
  @include breakpoint(desktop) {
    font-size: 30px;
    text-align: start;
    padding-left: 0;
  }
}

.UserRow {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  @include breakpoint(desktop) {
    flex-direction: row;
  }
}

.NameCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint(desktop) {
    align-items: flex-start;
  }
}

.Avatar.Avatar {
  width: 100px;
  height: 100px;
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
}

.TabsContainer {
  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs-nav-wrap,
    .ant-tabs-nav {
      width: auto;
    }
  }
}

.FioText {
  font-size: 24px;
  @include breakpoint(desktop) {
    font-size: 32px;
  }
}
