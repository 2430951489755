@import './variables';

@mixin breakpoint($breakpoint: mobile, $direction: min) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @if $direction == max {
      @media screen and (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    } @else if $direction == min {
      @media screen and (min-width: $breakpoint-value) {
        @content;
      }
    } @else {
      @media screen and ($direction: $breakpoint-value) {
        @content;
      }
    }
  } @else {
    @if $direction == max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint) {
        @content;
      }
    }
  }
}
