@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.TabsContainer.TabsContainer {
  width: 100%;
}

.Tabs {
  width: 100%;

  :global(.ant-tabs-tab) {
    width: 30%;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 12px;
  }
}

.SubRow {
  gap: 10px;
}

.SpecialtyRow {
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 20px;

  @include breakpoint(desktop) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.AgeToggle {
  position: absolute;
  top: 100%;

  @include breakpoint(desktop) {
    position: static;
    top: auto;
  }
}

.SpecialtyName,
.RegionName {
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.02em;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.Doctors {
  overflow: auto;
  background: none;
  min-height: auto;
  width: 100%;
  align-items: center;
  gap: 14px;
  height: 100%;

  @include breakpoint(desktop) {
    padding-bottom: 0;
  }
}

.Doctors::-webkit-scrollbar {
  display: none;
}

.Doctors {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.FilterRow {
  gap: 10px;
  width: 100%;
  @include breakpoint(desktop) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.Picker {
  height: 50px;
  max-width: 48%;

  @include breakpoint(desktop) {
    max-width: 270px;
  }
}

.Select {
  :global(div.ant-select-selector.ant-select-selector) {
    height: 50px;
  }
  flex-grow: 1;

  @include breakpoint(desktop) {
    max-width: 270px;
  }
}

.Hat {
  background: $background;
  z-index: 10;
  gap: 14px;
  padding-bottom: 14px;
}

.FixedHat {
  top: 0;
  position: sticky;
}

.DoctorRow {
  margin-bottom: 14px;
}
