@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.ExpieredTokenModal {
    :global {
        .ant-modal-close-x {
            display: none !important;
        }

        .ant-modal-content {
            min-height: 200px !important;
        }
    }
}

.ExpieredTokenModalCol {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ExpieredTokenModalRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.ExpieredTokenModalEnterButton {    
    width: 100%;
    max-width: 347px;
    height: 44px;
    border-radius: 10px;
    margin-bottom: 10px;
    
    @include breakpoint(desktop) {
      max-width: 400px;
    }
}