@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DocumentsWrapper {
  width: 100%;
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.Title {
  font-size: 16px;
  font-weight: 600;
}

.FilterRow {
  width: 100%;
  flex-wrap: nowrap;
  gap: 20px;
}

.Selector {
  width: 100%;
  :global(.ant-select) {
    flex-grow: 1;
  }
}

.GreyText {
  font-size: 12px;
  color: $textGrey;
  align-self: flex-start;
}

.AddButton {
  width: 100%;
  height: 44px;
}

.AddTab {
  width: 95px;
  min-width: 95px;
  height: 115px;
  border-radius: 20px;
  border: 1px solid $border;
  background: $background;
  //override element style
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
  gap: 11px;
  cursor: pointer;
}

.AddTabText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
}

.ButtonText {
  display: flex;
  align-items: center;
  gap: 10px;
  path {
    stroke: $white;
  }
}

.Active {
  border: 1.5px solid $primary;
}

.DocumentsSliderRow {
  flex-wrap: nowrap;
  overflow: auto;
  max-width: 100%;
}

.HiddenTab {
  display: none !important;
}

.MobileSelector {
  display: flex;
  @include breakpoint(desktop) {
    display: none;
  }
}

.DesktopSelector {
  display: none;
  @include breakpoint(desktop) {
    display: flex;
  }
}
