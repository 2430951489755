@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PrintBtn {
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1024px) {
    display: none !important;
  }
}

.SaveButton {
  width: 100%;
  max-width: 347px;
  height: 44px;
  border-radius: 10px;
  margin-bottom: 15px;

  @include breakpoint(desktop) {
    max-width: 400px;
  }
}

@media print {
  /* Hide all elements inside #root */
  .rpv-print__body-printing #root {
    display: none;
  }

  /* Show the prepared pages */
  .rpv-print__zone {
    display: block;
  }
}
