@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Area {
  padding: 12px;
  background-color: $white;
  border-radius: 14px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
  width: 100%;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.Block {
  border: 1px solid $border;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  margin-top: 20px;

  & > button {
    flex-shrink: 0;
  }
}

.Info {
  display: flex;
  margin-bottom: 10px;
}

.Data {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.Title {
  font-size: 14px;
  line-height: 18px;
  color: $primary;
  margin-bottom: 8px;
  margin-right: 20px;

  span {
    color: $textGrey;
  }
}

.Text {
  color: $textGrey;
  font-size: 12px;
  line-height: 16px;
}

.Button {
  width: 100%;
  margin-top: 20px;
  height: 44px;
}

.RedButton {
  width: 100%;
  margin-top: 20px;
  min-height: 44px;
  background-color: $red;
  white-space: wrap;
}

.Message {
  padding: 12px;
  border: 1px solid $border;
  border-radius: 8px;
  margin-top: 20px;

  @include breakpoint(desktop) {
    padding: 16px 45px;
  }

  span {
    margin-bottom: 20px;
    color: $textGrey;
  }
}

.TabsLabels {
  :global(.ant-tabs-tab-btn) {
    font-size: 12px;
    white-space: wrap;
    text-align: center;
    @include breakpoint(desktop) {
      font-size: 14px;
    }
  }
  & > :global(.ant-tabs-nav .ant-tabs-tab) {
    padding: 8px;
  }
}

.TabsLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px 10px;
  flex-direction: column;

  @include breakpoint(desktop) {
    flex-direction: row;
  }
}


