@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.MapContainer.MapContainer {
  width: 100%;
  background: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 5px;
  height: 50vh;

  @include breakpoint(desktop) {
    padding: 30px;
    min-height: 792px;
    height: auto;
  }
}

.Map {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;

  @include breakpoint(desktop) {
    gap: 24px;
  }

  :global(.ymaps-2-1-79-inner-panes) {
    border-radius: 12px;
  }
}
