@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.CardRow {
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: scroll;
  width: inherit;
  max-width: 100%;
}

.Card {
  height: 150px;
  min-width: 120px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  padding: 23px 14px;
  cursor: pointer;
  line-height: 16px;
  font-size: 12px;

  @include breakpoint(desktop) {
    line-height: 20px;
    font-size: 14px;
    height: 173px;
    min-width: 154px;
  }

  svg {
    margin-bottom: 10px;
    min-width: 38px;
    max-width: 38px;
    min-height: 38px;
    max-height: 38px;

    @include breakpoint(desktop) {
      margin-bottom: 20px;
      min-width: 50px;
      min-height: 50px;
      min-height: 50px;
      max-height: 50px;
    }
  }
}
