@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.Placemark {
  top: -40px;
  position: relative;
  left: -20px;
  cursor: pointer;
}

.BalloonContainer {
  width: 296px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: 'Inter', sans-serif;
}

.TopRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BranchName {
  color: $textPrimary;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.Text1 {
  font-family: 'Inter', sans-serif;
  color: $textGrey;
  font-size: 12px;
}

.PriceText {
  font-family: 'Inter', sans-serif;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  color: $textPrimary;
  background-color: rgba($orange, 0.2);
  border-radius: 40px;
  padding: 1px 10px;
  margin-right: 10px;
}

.PhoneText {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $primary;
  line-height: 16px;
}

.Button {
  height: 32px;
  width: 296px;
  border-radius: 6px;
  background-color: $primary;
  color: white;
  border: none;
}

.Button:hover {
  color: $primary;
  border: 1px solid $primary;
  background-color: rgba($primary, 0.08);
}

:global(ymaps.ymaps-2-1-79-balloon__content.ymaps-2-1-79-balloon__content) {
  margin-right: 0;
  padding: 16px;
  border-radius: 14px;
}

:global(ymaps.ymaps-2-1-79-balloon__layout.ymaps-2-1-79-balloon__layout) {
  border-radius: 14px;
}

:global(ymaps.ymaps-2-1-79-balloon.ymaps-2-1-79-balloon) {
  border-radius: 14px;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
}

.BalloonContainer li::marker {
  color: $primary;
}

.WorkingHours {
  white-space: break-spaces;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $black;
  line-height: 16px;
}

.ContactRow {
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 5px;
  display: flex;
  margin: 14px 0;
}

.DoctorsText {
  font-size: 12px;
  color: $textGrey;
}
