@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ClinicBlockRow {
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include breakpoint(desktop) {
    justify-content: flex-start;
    gap: 14px;
  }
}

.ClinicRow {
  gap: 10px;
}

.ClinicCol {
  max-width: 235px;
}

.ClinicName {
  cursor: pointer;
}

.Icon {
  align-self: flex-start;
  width: 26px;
  margin-top: 5px;
}

.AddressText {
  color: $textGrey;
  font-size: 12px;
}

.PhoneText {
  display: block;
  margin-bottom: 4px;
  color: $primary;
  font-size: 12px;
}

.Caret {
  margin-left: 4px;
}
