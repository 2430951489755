@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Content {
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.Widget {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 10000;
  min-height: inherit;
}

.LockBody {
  overscroll-behavior: none;
}
