@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.StatisticsCol {
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 4px;
  align-items: center;
  border-radius: 14px;
  padding: 12px 0;
  min-width: 95px;
  height: 115px;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid $border;

  & :global {
    .ant-image-img {
      height: 38px;
      object-fit: contain;
    }
  }
}

.SmallText {
  font-size: 12px;
}

.RoundNumber {
  border-radius: 50%;
  border: 1px solid $lightBlue;
  padding: 4px 10px;
  gap: 6px;
  min-width: 30px;
  height: 30px;
}
