@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ContactRow {
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 5px;
}

.RoutingCol {
  display: flex;
  gap: 5px;
}

.RoutingRow {
  justify-content: space-between;
}

.BlockContent {
  flex-direction: column;
  gap: 14px;
}

.Icon {
  margin-top: 4px;
  min-width: 16px;
}

.ClinicAddress {
  color: $textPrimary;
}

.MobileDivider {
  margin: 0;
  @include breakpoint(desktop) {
    display: none;
  }
}

.DesktopVisible {
  display: block;
}

.WorkingHours {
  white-space: break-spaces;
  color: $textPrimary;
}

.GreyText {
  font-size: 12px;
  color: $textGrey;
}

.MobileCol {
  display: flex;
  flex-direction: column;
}

.PhoneNumber.PhoneNumber {
  color: $primary;
}
