@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ContentArea {
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1160px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 16px 14px 40px;
  overflow-x: hidden;

  @include breakpoint(desktop) {
    border-radius: 0 0 15px 15px;
    position: relative;
    flex-direction: row;
    padding: 34px 10px 40px;
    gap: 30px;
  }
}

.ContentWrapper {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0 0 100px;
  margin: 0 auto;
  min-height: inherit;

  @include breakpoint(desktop) {
    max-width: 850px;
    gap: 20px;
    padding: 0;
    margin: 0;
  }
}

.NoMedCardDesktopCol {
  display: none;
  @include breakpoint(desktop) {
    display: flex;
  }
}

.NoMedCardMobileCol {
  display: flex;
  @include breakpoint(desktop) {
    display: none;
  }
}
