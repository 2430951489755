@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.FormItemWrapper {
  :global {
    .ant-select {
      width: 100%;
    }

    .ant-select-selection-item {
      font-size: 14px;
      top: 8px;
    }

    div.ant-select-selector.ant-select-selector {
      height: 50px;
      padding: 8px 16px;
    }

    .ant-select-arrow {
      inset-inline-end: 16px;
    }

    .ant-select-clear {
      display: flex;
      align-items: center;
      inset-inline-end: 40px;
      scale: 1.4;
    }
  }
}

:global(.ant-select-dropdown div.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
  background-color: $background;
}
