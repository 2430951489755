@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Icon {
  align-self: flex-start;
  margin-top: 5px;
  width: 26px;
}

.ServiceTag {
  color: $textGrey;
  font-size: 12px;
}

.ServiceRow {
  gap: 10px;
  flex-flow: row !important;
}
