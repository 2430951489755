@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DoctorInfoWrapper {
  background: $white;
  min-height: auto;
  box-shadow: 0 1px 5px rgba($black, 0.05);
  border-radius: 14px;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  @include breakpoint(desktop) {
    padding: 30px;
    padding-right: 193px;
  }
}

.Title {
  font-weight: 600;
  font-size: 16px;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
  line-height: 133%;
}

.BoldText {
  font-weight: 600;
  font-size: 16px;
  line-height: 112%;
  letter-spacing: -0.02em;
}

.MedcardPermissionRow {
  gap: 10px;
  flex-wrap: nowrap;
  align-items: flex-start;

  :global {
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      margin-top: -1px;
    }
  }
}

.LoadRow {
  justify-content: space-between;
  align-items: center;
}

.LoadButtonContent {
  align-items: center;
  gap: 10px;

  :global {
    .ant-typography {
      font-size: 12px;
    }
  }
}

.LoadText {
  font-weight: 400;
  color: $textGrey;
  font-size: 14px;
}

.AddDocsButton,
.SaveButton {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  @include breakpoint(desktop) {
    max-width: 323px;
  }
}

.SaveButton:disabled {
  background: $disabledInput;
  
  article {
    color: $greyBackground !important;
  }
}

.SaveButton:disabled:hover {
  background: $disabledInput;

  article {
    color: $greyBackground !important;
  }
}

.Input {
  height: 50px;
  width: 100%;
}

.InputSmall {
  height: 50px;
  width: 100%;
  padding-top: 26px;

  @include breakpoint(desktop) {
    width: 48%;
    max-width: 363px;
  }
}

.DiagnosisInput {
  height: 50px;
  padding: 16px 16px 0;
}

.InputRow {
  display: flex;
  flex-direction: row;
  gap: 14px;
  justify-content: space-between;

  @include breakpoint(desktop) {
    flex-wrap: nowrap;
  }

  & :global {
    .ant-form-item {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.ImagesRow {
  margin-bottom: 30px;
}

.ImageBlock {
  margin-right: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .ant-image {
      img {
        background: $background;
        border-radius: 10px;
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      &.ant-image-error {
        img {
          display: none;
        }

        &:after {
          display: block;
          content: '';
          background: url('../../../assets/ErrorImage.svg') no-repeat center;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.RemoveImage {
  position: absolute;
  right: 0;
  top: 0;
  background: $white url('../../../assets/Cross.svg') no-repeat center;
  border: 1px solid $buttonBorder;
  border-radius: 40px;
  width: 22px;
  height: 18px;
  cursor: pointer;
  transform: translate(50%, -50%);
}

.FileUploader {
  :global {
    .ant-upload-drag {
      border: none;
    }
    .ant-upload-btn {
      display: inline !important;
    }
    .ant-upload-icon,
    .ant-upload-list-text {
      display: none;
    }
  }
}

.Form {
  min-height: auto;
  border-radius: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.DocumentsListConatinet {
  max-width: 500px;
}

.AnalysesBlock {
  flex-direction: column;
  gap: 14px;
  margin-top: 16px;

  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.BoldMessageText {
  font-weight: 600;
}

.ShareMessageBlock {
  margin-bottom: 16px;
}

.DisabledMedcardBtn {
  border: none;
  padding: 10px, 16px, 10px, 16px;
  border-radius: 10px;
  width: 230px;
  height: 36px;
  color: $greyBackground;
  background: $disabledInput;
  font-size: 12px;
  line-height: 16px;
}

.MedcardRow {
  align-items: center;

  @media (max-width: 692px) {
    gap: 10px;
    margin-bottom: 15px;
  }
}

.MedcardActionsLinks {
  color: $primary;
  font-size: 12px;
  line-height: 16.8px;
  border-bottom: 1px $primary solid;
  border-radius: 0px;
  padding: 0px;
  margin-right: 10px;
  margin-left: 10px;
  height: 20px;
}

.MedcardActionsLinks:hover {
  color: $primary !important;
}
