@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.ServiceTypesWrapper {
  flex-direction: column;
  gap: 10px;
}

.ServiceTypeButton {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 10px 9px;

  @include breakpoint(desktop) {
    padding: 11px 14px;
  }

  svg {
    width: 26px;
    height: 26px;
  }
}

.ServiceTypeRow {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.ServiceTypeText.ServiceTypeText {
  color: $textPrimary;
}

.ServiceTypeSubCol {
  display: flex;
  gap: 11px;
  align-items: center;
}

.ServiceTypeCaretCol {
  border-radius: 50%;
  background-color: $white;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ServiceTypeCaret.ServiceTypeCaret {
  margin-left: 5px;
  max-width: 12px;
  max-height: 12px;
}
