@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PromoBlockWrapper {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  gap: 20px;
  @include breakpoint(desktop) {
    gap: 30px;
  }
}
