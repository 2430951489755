@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.AppointmentWrapper {
  width: 100%;
  background: $white;
  border-radius: 14px;
  padding: 14px;
  gap: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);

  :global(.ant-divider-horizontal) {
    margin: 0;
  }

  @include breakpoint(desktop) {
    padding: 30px;
    gap: 20px;
  }
}

.DoctorRow {
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 14px;

  @include breakpoint(desktop) {
    flex-direction: row;
  }
}

.DoctorCol {
  gap: 10px;
  max-width: 321px;
}

.DoctorCol,
.ButtonsCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Date {
  font-size: 18px;
  font-weight: 600;
}

.DayOfWeek {
  font-size: 12px;
  color: $black;
}

.DateRow {
  align-items: center;
  justify-content: space-between;
}
