@import 'src/styles/mixins';
@import 'src/app/theme.scss';

h3.Title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

h4.Subtitle {
  margin: 0;
  font-size: 14px;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.ProfileRow {
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.Divider {
  margin: 0;
}

.Row {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.LinkRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.GreyText {
  color: $textGrey;
}

.GreenRow {
  justify-content: center;
  align-items: center;
  border: 1px solid $green;
  gap: 10px;
  border-radius: 40px;
  padding: 4px 10px;
}

.Col {
  display: flex;
  align-items: center;
  gap: 5px;
}

.RoundText {
  display: flex;
  padding: 4px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 40px;
  border: 1px solid $border;
}

.AddButton {
  width: 100%;
  height: 36px;
}

.VerticalRow {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.Text {
  max-width: 232px;
  text-align: center;
}

.ImagePlaceholder {
  max-width: 300px;
}
