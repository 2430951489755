@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.CustomTag {
  color: $textPrimary;
  border: 0.5px solid $buttonBorder;
  border-radius: 100px;
  background: transparent;
  display: flex;
  align-items: center;
  margin-inline-end: 0;
  padding: 2px 10px;
  white-space: break-spaces;
}

.Active {
  background-color: $primary;
  color: $white;
}

.Grey {
  color: $textPale;
  border-color: $textPale;
}

.Warn {
  color: $red;
  border-color: $red;
}
