@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DoctorCarousel {
  flex-wrap: nowrap;
  overflow: auto;
  gap: 20px;
  max-width: 100%;
}

.DoctorShortCol {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  min-width: 105px;
  text-align: center;
  max-width: 130px;
}

.DocFio {
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 22px;
}

.DocBranch {
  font-size: 12px;
  color: $textGrey;
}

.DocSpec {
  font-size: 12px;
  color: $textGrey;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Arrows.Arrows {
  position: absolute;
  right: 24px;
  top: 25px;

  :global(.ant-btn-default) {
    border: 0px !important;
    box-shadow: none !important;
  }
}

.Initials {
  font-size: 26px;
  @include breakpoint(desktop) {
    font-size: 36px;
  }
}

.Empty {
  svg {
    width: 100%;
  }

  span {
    text-align: center;
    color: $textGrey;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: block;
    max-width: 150px;
    margin: 0 auto;
  }
}

.DocAvatar.DocAvatar.DocAvatar,
.DocAvatar :global(.ant-skeleton-avatar.ant-skeleton-avatar.ant-skeleton-avatar) {
  width: 60px;
  height: 60px;
  object-fit: cover;

  @include breakpoint(desktop) {
    width: 90px;
    height: 90px;
  }
}
