.ant-btn {
  border-radius: 10px;
}
.custom_progress_bar {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-progress-outer {
    width: 100% !important; 
    margin: 0 !important;
    padding: 0 !important;

    .ant-progress-inner {
      border-radius: 10px;
      .ant-progress-bg {
        border-radius: 10px;
      }
    }
  }
  .ant-progress-text {
    position: absolute;
    color: white !important;
    left: calc(50% - 14px);
    top: calc(50% - 8px);
  }
}
