@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.AnalysisWrapper {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  gap: 14px;
  width: inherit;
}

.CalendarRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.CasesRow {
  width: 100%;
  gap: 16px;
}

h3.Title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

h4.Title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.TreatmentRow {
  font-size: 12px;
  display: block;

  & .ShowAll {
    font-size: 12px;
    color: #7f49de;
    margin-left: 10px;
    cursor: pointer;
  }
}

.RecommendationsRow {
  width: 100%;
  gap: 30px;
}

.RecommendationsTitle.RecommendationsTitle {
  margin-top: 16px;
}
