@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.TabContainer {
  border: 1px solid $lightBlue;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  padding: 1px;
  width: 100%;
  background-color: $lightBlue;
  font-weight: 300;

  :global {
    .ant-tabs,
    .ant-tabs-nav-wrap,
    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-tab {
      width: 100%;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
      display: none;
    }
  }
}

div.TabContainer :global(div.ant-tabs-tab-disabled.ant-tabs-tab-disabled) {
  cursor: not-allowed;
}

div.TabContainer.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab) {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  border-color: $lightBlue;
}

.TabContainer :global(.ant-tabs-nav),
.TabContainer :global(.ant-tabs-nav-wrap),
.TabContainer :global(.ant-tabs-nav-list) {
  width: 100%;
  margin: 0;
}

div.TabContainer.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab-active) {
  color: $textPrimary;
}

div.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):hover,
div.TabContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):active {
  color: $primary;
}

div.TabContainer :global(.ant-tabs-tab-disabled.ant-tabs-tab-disabled.ant-tabs-tab-disabled.ant-tabs-tab-disabled) {
  color: $disbledButtonText;
  cursor: not-allowed;
}

:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn.ant-tabs-tab-btn) {
  color: $textPrimary;
}

.TabContainer .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  display: none;
}

.TabContainer :global(.ant-tabs-nav::before) {
  display: none;
}
