@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.CalendarRecordsListWrapper {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;

  :global {
    .ant-row {
      width: 100%;
    }
  }
}

// .Tabs div div div {
//   font-size: 12px;
// }

// .Tabs {
//   :global {
//     .ant-tabs-tab.ant-tabs-tab {
//       padding: 9px 12px;
//     }
//   }
// }
