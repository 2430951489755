@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.DocInfo {
  background: transparent;
  min-height: auto;

  @include breakpoint(desktop) {
    max-width: 266px;
  }
}

.AvatarCol {
  @include breakpoint(desktop) {
    width: 100%;
  }
}

.DocRow {
  gap: 13px;
}

.DocAvatar.DocAvatar {
  width: 60px;
  height: 60px;
  object-fit: cover;

  @include breakpoint(desktop) {
    width: 140px;
    height: 140px;
  }
}

.NameCol {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 230px;
  @include breakpoint(desktop) {
    max-width: 300px;
  }
}

.FullName {
  font-weight: 600;
  font-size: 16px;
  gap: 10px;
  display: flex;
  align-items: center;
  color: $textPrimary;
  cursor: pointer;
  
  &:hover {
    color: $primary;
  }
}

.ExperienceText {
  font-size: 12px;
  font-weight: 400;
  color: $textGrey;
}

.TagRowContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 17px;
}

.TagRow {
  gap: 5px;
}

.LabelRating {
  font-size: 12px;
  font-weight: 600;

  @include breakpoint(desktop) {
    font-size: 14px;
  }
}

.LabelReviews {
  font-size: 12px;
  color: $primary;

  @include breakpoint(desktop) {
    font-size: 14px;
  }
}

.PromoRow {
  gap: 5px;
  align-items: center;
}

.Link {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.LabelPromo {
  background-color: rgba($orange, 0.2);
  padding: 4px 10px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}

.DoctorInitials {
  font-size: 26px;
  @include breakpoint(desktop) {
    font-size: 36px;
  }
}
