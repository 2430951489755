@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.NoRecordsContainer {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;

  @include breakpoint(desktop) {
    padding: 175px 0 30px 0;
    margin-top: auto;
    max-height: fit-content;
  }
}

.AddNewDumpDesktop {
  width: 347px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  margin: 80px auto 14px auto;
  justify-content: center;

  @include breakpoint(desktop) {
    width: 186px;
    margin: 30px auto 20px auto;
    justify-content: start;
  }

  span {
    color: $white;
  }
}

.RecordsDumpText {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.DumpIconWrapper {
  display: flex;
  justify-content: center;
}

.AddButtonRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.PlusIcon {
  min-width: 16px;
  path {
    stroke: $white;
  }
}
