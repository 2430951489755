@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ReviewsTitle.ReviewsTitle {
  font-size: 16px;
  margin: 0;
}

.TotalReviewsAndButton {
  width: 100%;
  justify-content: space-between;
}

.TotalReviewsBlock {
  flex-wrap: nowrap;
}

.Average {
  margin-right: 14px;
  white-space: nowrap;

  :global(.ant-typography) {
    font-size: 48px;
    line-height: 100%;
  }
}

.ProgressRows {
  width: 100%;
  margin-bottom: 20px;
}

.ProgressRow {
  flex-wrap: nowrap;
  align-items: center;
  height: 7px;
  margin-bottom: 3px;

  :global {
    .ant-progress-line {
      margin-bottom: 0;
    }
    .ant-progress {
      .ant-progress-outer,
      .ant-progress-inner {
        display: block;
      }
    }
  }
}

.Stars {
  flex-wrap: nowrap;
  width: 70px;
  justify-content: flex-end;

  svg {
    flex-shrink: 0;
    width: 7px;
    margin-right: 3px;
  }
}

.Message {
  margin-bottom: 10px;
}

.AddReview {
  width: 100%;
  max-width: 385px;
  height: 44px;
  margin-bottom: 10px;
}

.Review {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    padding-bottom: 20px;
    border-bottom: 1px solid $border;
  }
}

.Info {
  flex-wrap: nowrap;
}

.CommentInfo {
  flex-wrap: nowrap;
  margin-top: 20px;
  position: relative;
  right: 30px;

  @include breakpoint(desktop) {
    position: static;
  }

  :global .ant-col {
    margin-right: 4px;
  }
}

.ImageCol {
  width: 26px;
  margin-right: 10px;
  flex-shrink: 0;
}

.Photo {
  max-width: 26px;
  height: 26px;
  background: $backgroundAvatar;
  border-radius: 50%;
  object-fit: contain;
}

.ImageReplace {
  width: 26px;
  height: 26px;
  font-size: 14px;
}

.DataCol {
  display: flex;
  flex-direction: column;

  h4.CommentTitle {
    font-size: 14px;
    line-height: 130%;
    margin-top: 0;
    margin-bottom: 4px;
  }

  .Comment {
    font-size: 14px;
    line-height: 130%;
  }
}

.Name {
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin: 0 0 4px;
}

.Date {
  font-size: 12px;
  line-height: 133%;
  color: $textGrey;
  margin-bottom: 4px;
}

.Rate {
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: -0.02em;
  display: flex;
  margin-bottom: 15px;

  &:before {
    content: '';
    background: url('../../../assets/Star.svg') no-repeat center;
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
}

.ClinicText {
  font-size: 12px;
  line-height: 133%;
  color: $textGrey;
}

.DoctorFio {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.DoctorBlock {
  margin-bottom: 20px;
  margin-top: 10px;

  @include breakpoint(desktop) {
    margin-top: 0;
  }
}

.CommentsBlockContainer {
  @include breakpoint(desktop) {
    width: 579px;
  }
}

.CommentsBlockContentContainer {
  @include breakpoint(desktop) {
    display: flex;
    gap: 40px;
  }
}

.DoctorNameBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  @include breakpoint(desktop) {
    max-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-overflow: ellipsis;
  }
}

.RateBlock {
  margin-top: 4px;
}
