@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.SharedLinkWrapper {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid $inputBorder;
  width: 100%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
}

.ButtonRow {
  width: 100%;
  gap: 11px;
  @include breakpoint(desktop) {
    flex-wrap: nowrap;
  }
}

.Button {
  width: 100%;
  height: 36px;
}

.ButtonText {
  font-size: 12px;
}

.LinkText.LinkText {
  color: $primary;
}

.LinkDisabled {
  pointer-events: none;
}

.DateText {
  font-weight: 600;
}

.ExpireText {
  font-size: 12px;
  color: $textGrey;
}

.TextRow {
  justify-content: space-between;
  width: 100%;
}

.Avatar.Avatar {
  max-width: 36px;
}

.accessTag {
  margin-top: 10px;

  @include breakpoint(desktop) {
    margin-top: 0;
  }
}
