@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.InvitationButton {
  width: 100%;
  height: 44px;
}

.InvitationButtonRow {
  align-items: center;
  gap: 5px;
  path {
    stroke: $white;
  }
}
