@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.BlockRow {
  width: 100%;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  @include breakpoint(desktop) {
    margin-top: 0;
    border-radius: 14px;
    flex-direction: row;
    padding: 23px;
    gap: 24px;
  }

  :global(.ant-carousel) {
    width: 100%;
    @include breakpoint(desktop) {
      max-width: 375px;
    }
  }
}

.Divider {
  margin: 0;
  display: none;
  @include breakpoint(desktop) {
    display: block;
  }
}

.ChatButton {
  width: 100%;
  height: 44px;

  @include breakpoint(desktop) {
    width: 154px;
  }
}

.AppointmentButton {
  width: 100%;
  height: 44px;
  @include breakpoint(desktop) {
    width: 151px;
  }
}

.ClinicName {
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.32px;
}

.ButtonsWrapper {
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 11px;
  margin-top: 14px;

  @include breakpoint(desktop) {
    flex-direction: row;
    width: 315px;
    margin-top: 0;
  }
}

.CardContentWrapper {
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @include breakpoint(desktop) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
