@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.WaitingListTitle {
  font-size: 18px;
  color: $primaryDark;
  line-height: 24px;
  font-weight: 600;
  align-self: self-start;
}

.WaitingListContainer {
  display: flex;
  width: 100%;
  margin-top: 24px;
  gap: 14px;
  background: none;
}

.MilaMessageBlock {
  padding: 14px 12px;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}
