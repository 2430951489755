@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Arrows {
  display: none;
  @include breakpoint(desktop) {
    display: flex;
  }
}

.Arrow {
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background: $white;

    svg path {
      stroke: $textGrey;
    }
  }

  &.ArrowPrev {
    transform: rotate(90deg);
  }

  &.ArrowNext {
    transform: rotate(-90deg);
    margin-left: 15px;
  }
}
