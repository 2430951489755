@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.InfoRow {
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  gap: 15px;
}

.Link {
  display: inline;
}

.Message {
  white-space: pre-line;
  max-width: 480px;
  article {
    display: contents;
  }
}

.Image {
  max-width: 306px;
}

.Title {
  font-size: 24px;
  font-weight: 600;
  color: $textPrimary;
}

.ModalMessageInfo {
  display: flex;
  flex-direction: column;
}

.ModalMessageText {
  color: $textGrey;

  &.ModalMessageBoldText {
    color: $textPrimary;
  }
}

.LinkText {
  color: $primary;
}
