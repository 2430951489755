@import '/src/app/theme.scss';

.NavTabsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

.DefaultTab {
  font-size: 16px;
  color: $textGrey;
  cursor: pointer;

  &:hover {
    color: $menuHover;
  }
}

.ActiveTab {
  color: $primary;
}
