@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.input {
  padding: 16px;

  &  :global {
    .ant-input {
      height: 56px;
    }
  }
}
