@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.MessageContainer {
  flex-direction: column;
  width: 347px;
  align-self: center;

  @include breakpoint(desktop) {
    width: 560px;
  }
}

.HiddenContainer {
  opacity: 0;
  align-self: center;
}

.AnimationShowContainer {
  align-self: center;
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.SingleButtonsContainer {
  flex-direction: column;
  width: 347px;
  align-self: center;
  margin-top: -14px;

  @include breakpoint(desktop) {
    width: 560px;
  }
}

.MessageBlock {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
}

.MessageText {
  line-height: 130%;
  max-width: 265px;

  @include breakpoint(desktop) {
    max-width: 488px;
    font-size: 16px;
    line-height: 24px;
  }
}

.MessageRow {
  flex-wrap: nowrap;
  gap: 10px;
  border: 1px solid $border;
  border-radius: 4px 10px 10px 10px;
  padding: 14px;
  box-shadow: 0 3px 5px rgba($black, 0.05);
  background-color: $white;
  margin-left: 10px;
  width: 307px;
  flex-direction: column;

  @include breakpoint(desktop) {
    padding: 14px 16px;
    width: 520px;
  }
}

.ClinicOptionBlock {
  min-height: 44px;
  min-width: 279px;
  border: 1px solid $border;
  align-content: center;
  border-radius: 10px;
  padding: 14px 22px;
}

.MedOrganizationText.MedOrganizationText,
.ChildrenNameText.ChildrenNameText {
  color: $textGrey;
  font-size: 14px;

  @include breakpoint(desktop) {
    font-size: 16px;
  }
}

.AcceptButton {
  min-height: 44px;
  height: auto;
  padding: 14px;
  border-radius: 10px 2px 10px 10px;
}

.AcceptButtonText {
  line-height: 16px;
  white-space: pre-wrap;
  text-align: start;

  @include breakpoint(desktop) {
    font-size: 16px;
  }
}

.AcceptButtonWrapper {
  align-self: flex-end;
}

.DeclineButtonWrapper {
  width: 100%;
  justify-content: flex-end;
}

.DeclineButton {
  min-height: 44px;
  height: auto;
  padding: 14px;
  border: 1px solid $darkGreyBorder;
}

.DeclineButtonText {
  line-height: 16px;
  white-space: pre-wrap;
  text-align: start;

  @include breakpoint(desktop) {
    font-size: 16px;
  }
}

.ButtonsWrapper {
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 26px;
  max-width: 270px;
  align-self: flex-end;

  @include breakpoint(desktop) {
    margin-top: 30px;
    max-width: 483px;
  }
}

.TextLink.TextLink {
  color: $primary;
  text-decoration: underline;
  line-height: 130%;
  font-size: 14px;

  @include breakpoint(desktop) {
    line-height: 24px;
    font-size: 16px;
  }
}

.DisabledButtonText.DisabledButtonText {
  color: $textGrey !important;
}

.DisabledButton.DisabledButton {
  :global(.ant-typography) {
    color: $textGrey;
  }
}

.ChildrenBlock {
  height: 44px;
  width: 279px;
  border: 1px solid $border;
  align-content: center;
  border-radius: 10px;
  padding: 14px 22px;

  @include breakpoint(desktop) {
    min-height: 44px;
    height: auto;
  }
}

.ChildrenBirthdayText {
  font-size: 10px;
  line-height: 12px;
  color: $textPale;
}

.ChildrenBlock {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ChildrenNamesBlock {
  display: flex;
  flex-direction: column;
}

.ChildrenNameCheckbox {
  :global {
    .ant-checkbox-inner {
      border-radius: 50%;
    }

    .ant-checkbox-checked:after {
      border-radius: 50%;
    }
  }
}

.MilaIcon {
  max-width: 30px;
}
