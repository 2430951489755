@import '/src/app/theme.scss';
@import 'src/styles/mixins';

.Placemark {
  top: -40px;
  position: relative;
  left: -20px;
  cursor: pointer;
}

.BalloonContainer {
  width: 219px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: 'Inter';
}

.BranchName {
  color: $textPrimary;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
}

.Button {
  height: 32px;
  width: 219px;
  border-radius: 6px;
  background-color: $primary;
  color: white;
  border: none;
}

.Button:hover {
  color: $primary;
  border-color: $primary;
  border: 1px solid;
  background-color: rgba($primary, 0.08);
}

:global(ymaps.ymaps-2-1-79-balloon__content.ymaps-2-1-79-balloon__content) {
  margin-right: 0;
  padding: 16px;
}

.BalloonContainer li::marker {
  color: $primary;
}
