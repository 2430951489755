@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.CalendarAppointmentRow {
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 14px;
  max-width: 403px;
  padding: 12px;
  justify-content: flex-start;
  height: fit-content;
}

.Divider {
  margin: 0;
}

.TextGrey {
  font-size: 12px;
  color: $textGrey;
}

.DateText {
  font-weight: 600;
}

.TopRow {
  align-items: center;
  gap: 10px;
}

.NoDocumentRow.NoDocumentRow {
  align-items: center;
  color: $red;
  border-color: $red;
  display: flex;
  gap: 5px;
}

.RoundedLabel {
  font-size: 12px;
  border-radius: 40px;
  border: 1px solid $buttonBorder;
  padding: 4px 10px;
}

.WarnIcon {
  width: 18px;
  height: 18px;
  path {
    stroke: $red;
  }
}

.Button {
  width: 100%;
  height: 44px;
}

.ShowButton {
  width: 91px;
  height: 36px;
}

.DocumentRow {
  width: 100%;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid $border;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.FileNameRow {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

.FileName {
  font-weight: 600;
}
