@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.TabsContainer {
  width: 347px;

  @include breakpoint(desktop) {
    width: 400px;
  }

  :global(.ant-tabs-nav-list) {
    background-color: $lightBlue;
    height: 36px;
    border-radius: 8px;
    padding: 2px;
  }

  :global(.ant-tabs-nav::before) {
    border: none !important;
  }
}

.TabBtn {
  :global(.ant-tabs-tab) {
    height: 32px;
    border-radius: 8px !important;
    border: 1px solid $lightBlue !important;
    padding: 9px 20px !important;
    width: 178px;

    @include breakpoint(desktop) {
      width: 100%;
    }
  }

  :global(.ant-tabs-tab-active) {
    background-color: $white;
    border: 1px solid $lightBlue !important;
  }
}
@media (min-width: 1024px) {
  :global(.styles_TabContainer__fPAxo) :global(.ant-tabs-nav-list) :global(.ant-tabs-tab) {
    height: 100%;
  }
}
.MessageBlock {
  width: 347px;

  @include breakpoint(desktop) {
    width: 400px;
  }
}
