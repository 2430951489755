@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DoctorContainer {
  background-color: $white;
  padding: 12px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  @include breakpoint(desktop) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 30px;
    width: 850px;
  }
}

.Divider {
  margin-top: 18px;
  margin-bottom: 18px;
  @include breakpoint(desktop) {
    display: none;
  }
}

.SignUpButton {
  width: 100%;
  height: 44px;
}

.MiniContainer {
  @include breakpoint(desktop) {
    flex-wrap: wrap;
    max-width: 270px;
    gap: 20px;
    align-items: center;
    padding: 20px;
  }
}

.Text {
  color: $textGrey;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  @include breakpoint(desktop) {
    width: 494px;
  }
}
