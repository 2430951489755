@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Block {
  padding: 12px;
  background-color: $white;
  border-radius: 14px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.Top {
  border-bottom: 1px solid $border;
  padding-bottom: 14px;
  margin-bottom: 14px;
  width: 100%;
  align-items: flex-start;
}

.TopData {
  flex-wrap: nowrap;
}

.TopPicture {
  width: 38px !important;
  height: 38px !important;
  margin-right: 10px;
  flex-shrink: 0;
}

.TopInfo {
  max-width: 534px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 10px;
}

.DateCreate {
  font-size: 12px;
  line-height: 16px;
}

.Name {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.DateApproval {
  display: none;

  @include breakpoint(desktop) {
    display: block;
    border: 1px solid $buttonBorder;
    font-size: 12px;
    line-height: 18px;
    padding: 4px 10px;
    border-radius: 40px;
    margin-left: auto;
  }
}

.StatusBlock {
  display: block;
  border: 1px solid $buttonBorder;
  font-size: 12px;
  line-height: 18px;
  padding: 4px 10px;
  border-radius: 40px;
  margin-left: auto;
}

.StatusBlockActive {
  border-color: $green;
}

.StatusBlockUnActive {
  border-color: $red;
}

.StatusBlockIcon {
  margin-right: 5px;
}

.Doctor {
  display: flex;
  gap: 2px;
  flex-direction: column;
  margin: 0 0 14px 6px;
}

.DoctorName {
  font-size: 14px;
  line-height: 16px;
  display: flex;

  &::after {
    content: '';
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    margin-top: 6px;
    width: 4px;
    height: 4px;
    margin-left: 4px;
    transform: rotate(-45deg);
  }
}

.DoctorProfession {
  font-size: 12px;
  line-height: 16px;
  color: $textGrey;
}

.ClinicBlock {
  flex-wrap: nowrap;
}

.Clinic {
  display: flex;
  gap: 2px;
  flex-direction: column;
  margin: -3px 0 14px 6px;
}

.Bottom {
  justify-content: space-between;
  width: 100%;
}

.Type {
  border: 1px solid $buttonBorder;
  font-size: 12px;
  line-height: 18px;
  padding: 4px 10px;
  border-radius: 40px;
}

.ButtonBlock {
  @include breakpoint(desktop, max) {
    margin-top: 14px;
    padding-top: 14px;
    border-top: 1px solid $border;
    width: 100%;
  }
}

.Button {
  width: 100%;
}

