@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ServiceCardRow {
  // Overriding inline style
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-width: fit-content;
}

.ServiceCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 101px;
  width: 101px;
  border-radius: 20px;
  padding: 12px 9px;
  position: relative;

  @include breakpoint(desktop) {
    height: 142px;
    width: 142px;
    padding: 17px 12px;
  }
}

.SpecialtyName {
  font-size: 12px;
  @include breakpoint(desktop) {
    font-size: 13px;
  }
}

.ServiceIcon.ServiceIcon.ServiceIcon,
.ServiceIcon img {
  max-width: 55px;
  max-height: 40px;
  align-self: flex-end;
  object-fit: contain;
  @include breakpoint(desktop) {
    max-width: 77px;
    max-height: 77px;
  }
}

.Price {
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  color: $textPrimary;
  background-color: rgba($orange, 0.2);
  border-radius: 40px;
  padding: 1px 10px;
  white-space: nowrap;
}
