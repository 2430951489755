@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.ModalContainer {
  @include breakpoint(desktop) {
    max-height: 366px;
  }
}

.CancelRecordWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .MilaMessageWrapper {
    width: 100%;
    justify-content: center;
    max-width: 347px;
    margin: 0 auto;
    padding-top: 6px;

    @include breakpoint(desktop) {
      max-width: 400px;
    }
  }
}

.CancelRecordConfirmed {
  font-weight: 600;
  font-size: 24px;
  color: $primaryDark;
  margin: 0 auto;
  margin-bottom: 14px;

  @include breakpoint(desktop) {
    padding-bottom: 20px;
    padding-top: 30px;
    margin-bottom: 0;
  }
}

.pict {
  margin-bottom: 35px;
  margin-top: 97.76px;

  @include breakpoint(desktop) {
    margin-top: 0;
  }
}

.PictWrapper {
  margin: 0 auto;
}
