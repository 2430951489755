@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Consent {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $border;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 14px;

  & :global {
    h3.ant-typography {
      color: $textPrimary;
      font-size: 14px;
      line-height: 130%;
      margin: 0;

      @include breakpoint(desktop) {
        font-size: 18px;
      }
    }

    a.ant-typography {
      color: $primary;
    }

    .ant-col:last-child {
      flex-shrink: 0;
    }
  }
}

.MilaMessageWrapper {
  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.ConsentTextBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ConsentDate {
  font-size: 12px;
  line-height: 16px;
  color: $textGrey;
}

.ConsentsRow {
  margin: 6px 0;

  @include breakpoint(desktop) {
    margin: 10px 0;
  }
}

.HiddenConsent {
  display: none;
}
