@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.TabsLabels {
  :global(.ant-tabs-tab-btn) {
    font-size: 12px;
    @include breakpoint(desktop) {
      font-size: 14px;
    }
  }
}

.RecordsScroll {
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.NoDocumentsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $white;
  border-radius: 14px;
  padding: 30px
}

.NoDocumentsTitle {
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0;
  text-align: center;
}

.NoDocumentsText {
  max-width: 584px;
  text-align: center;
}

.Button {
  margin-top: 20px;
  height: 44px;
  
  @include breakpoint(desktop) {
    min-width: 584px;
  }
  
}
