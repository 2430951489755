@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PatientFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  border-radius: 14px;
  width: 100%;
  padding: 12px;
  background-color: $white;
}

.Form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  :global(.ant-form-item) {
    margin-bottom: 0px;
    width: 100%;
  }
}

.Title {
  font-weight: 600;
  font-size: 16px;
}

.GenderRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.InputField {
  padding: 30px 16px 10px;
  height: 50px;
  border-color: $inputBorder;
}
