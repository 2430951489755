@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Price,
.WaitingPaymentPrice,
.PaidPrice {
  background: transparent;
  border-radius: 14px;
  width: fit-content;
  padding: 2px 10px;
}

.WaitingPaymentPrice {
  border-color: $red;
  color: $red;
}

.PaidPrice {
  border-color: $green;
}

.TagIcon {
  margin-right: 6px;
}
