@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.WhiteBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 15px;
  background: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;

  @include breakpoint(desktop) {
    padding: 30px;
  }
}

.ShowAllText {
  color: $primary;
  cursor: pointer;
  align-self: center;
}

.AgeRow {
  align-items: flex-start;
  flex-direction: column;
  gap: 14px;
  @include breakpoint(desktop) {
    align-items: center;
    flex-direction: row;
    gap: 34px;
  }
}

.SpecialtiesRow {
  gap: 10px;
  @include breakpoint(desktop) {
    gap: 20px;
  }
}

.Title {
  font-weight: 600;
  font-size: 16px;
}
