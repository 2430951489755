@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.Footer {
  background-color: $background;
  padding: 0;
  @include breakpoint(desktop) {
    border-top: 1px solid $divider;
  }
}

.DesktopFooterRow {
  display: none;
  background-color: $background;
  width: 100%;
  min-height: 8vh;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  padding: 30px 0;
  max-width: 1140px;
  margin: auto;

  @include breakpoint(desktop) {
    display: flex;
    position: relative;
  }
}

.DesktopSubRow {
  gap: 10px;
  align-items: center;
}

.DesktopSubRow2 {
  width: 416px;
  justify-content: space-between;
}

.DesktopSubRow3 {
  gap: 33px;
}

.DesktopFooterCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 420px;
  gap: 9px;
}

.DesktopFooterCol2 {
  gap: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DesktopText {
  color: $textPrimary;
  font-size: 12px;
}

.DesktopTextSmall {
  color: $textGrey;
  font-size: 10px;
}

.DesktopLinkSmall.DesktopLinkSmall {
  color: $textGrey;
  font-size: 10px;
  text-decoration: underline;
  display: inline-flex;
  margin-left: 5px;
  &:hover {
    color: $textGrey;
  }
}

.DesktopLink.DesktopLink {
  font-size: 12px;
  color: $primary;

  &:hover {
    color: $textGrey;
  }
}

.MilaIcon {
  max-width: 81px;
}

.MobileFooterRow {
  display: flex;
  width: 100%;
  min-height: 8vh;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 10px 10px 26px;
  background-color: $white;
  z-index: 6;
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.04);
  @include breakpoint(desktop) {
    display: none;
  }
}

.FooterCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  path {
    stroke: $textPrimary;
  }
}

.ActiveCol {
  article {
    color: $primary;
  }
  path {
    stroke: $primary;
  }
}

.FooterLabel {
  font-size: 10px;
}
