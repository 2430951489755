@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Spinner {
  color: $primary;
  padding: 40px;
}

.Spinner :global(.ant-spin-dot-item) {
  background-color: $primary;
}
