@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.MainRow {
  gap: 20px;
  @include breakpoint(desktop) {
    flex-wrap: nowrap;
    background-color: $white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    padding: 30px;
  }
}

.LeftCol {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include breakpoint(desktop) {
    max-width: 375px;
  }
}

.RightCol {
  width: 100%;
  max-width: 385px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.BlockContainer {
  background: $white;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 14px;
  border-radius: 14px;
  text-align: center;
  width: 100%;
}

.PrepBlock {
  @include breakpoint(desktop) {
    border: 1px solid $border;
    border-radius: 14px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    padding: 20px 12px;
    max-height: 169px; 
  }
}

.SendButton {
  padding: 0;
  border-radius: 6px;
  background-color: rgba($primary, 0.12);
  height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  svg {
    min-width: 40px;
    min-height: 12px;
  }
}

.EmailRow {
  width: 100%;
}

.EmailForm {
  display: flex;
  flex-wrap: nowrap;
  gap: 14px;
  align-items: center;
  width: 100%;
  div {
    margin-bottom: 0;
    width: 100%;
  }
}

.EmailInput {
  height: 50px;
  padding: 30px 16px 10px;
}

.SubTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.SubTitleNarrow {
  font-weight: 600;
  font-size: 16px;
}

.Link.Link.Link {
  color: $primary;
}

.PrimalButton {
  height: 44px;
  width: 100%;
}

.SecondaryButton {
  height: 44px;
  flex-grow: 1;
}

.ButtonsRow {
  width: 100%;
  gap: 11px;

  @include breakpoint(desktop) {
    display: none;
  }

  button {
    width: 48%;
  }
}

.PaleText {
  display: none;
  @include breakpoint(desktop) {
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $textGrey;
  }
}
