@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.card {
  align-items: center;
  background: transparent;
}

.card .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  text-align: center;
  color: $primaryDark;
  margin-bottom: 20px;

  @include breakpoint(desktop) {
    font-size: 24px;
  }
}

.pict {
  margin-bottom: 20px;
  max-width: 256px;
}

.card .subTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 133%;
  margin: 0 0 14px;

  @include breakpoint(desktop) {
    font-size: 18px;
  }
}

.messageBox {
  margin-bottom: 14px;
  background: $white;
  max-width: 460px;
}

.estimate {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;

  width: 100%;
  max-width: 460px;

  background: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
}

.rate {
  margin-bottom: 14px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.card .feedback {
  min-height: 120px;
  resize: none;
  margin-bottom: 14px;
}

.send {
  width: 100%;
  min-height: 44px;
}
