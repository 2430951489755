@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.Row {
  flex-wrap: nowrap;
  overflow: auto;
  gap: 20px;
  max-width: 100%;
}

.AddClinicButton {
  min-width: 109px;
  height: 109px;
  border: 1px solid $backgroundAvatar;
  border-radius: 20px;
  background: $background;
}

.AddClinicModal :global(.ant-modal-content) {
  background-color: $background;
}

.Arrows {
  position: absolute;
  right: 24px;
  top: 25px;

  :global(.ant-btn-default) {
    border: 0px !important;
    box-shadow: none !important;
  }
}

.Empty {
  flex-direction: column;
  gap: 10px;
  text-align: center;
  max-width: 109px;
  align-items: center;

  span {
    max-width: 100px;
    font-size: 12px;
    margin: 0;
    text-align: center;
    word-break: normal;
  }
}

.MyClinicsText {
  font-size: 12px;
  color: $textGrey;
  margin-top: 10px;
  width: 150px;
  text-align: center;
}

.MyClinicsDumpBlock {
  width: 374px;
  min-height: 109px;
  justify-content: center;
}

.TitleRow {
  gap: 8px;
}

.Title {
  color: $textPrimary;
  font-size: 16px;
  font-weight: 600;
  @include breakpoint(desktop) {
    font-size: 18px;
  }
}
