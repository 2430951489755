@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.DeleteMedcardModal {
  @media (max-width: 1024px) {
    max-height: 652px;
  }

  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 50px 110px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 1024px) {
        padding: 10px;
        background-color: $background;
        width: 100%;
        min-height: 375px;
        border-radius: 14px 14px 0 0;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 350px;
    }
  }
}

.DeleteMedcardModalMainRow {
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.DeleteMedcardModalTitle {
  color: $primaryDark;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

.DeleteMedcardModalInfoRow {
  border: 1px solid $border;
  border-radius: 4px 10px 10px 10px !important;
  padding: 16px;
  box-shadow: 0px 3px 5px 0px #0000000d;
  flex-flow: row !important;
  gap: 10px;

  @media (max-width: 1024px) {
    padding: 14px 16px 14px 16px;
    background: $white;
    width: 347px;
  }
}

.DescriptionCol {
  gap: 10px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.InfoTitle {
  color: $textPrimary;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
}

.InfoDescription {
  color: $textGrey !important;
  font-size: 14px;
  line-height: 18px;
}

.DeleteMedcardModalBtn {
  padding: 14px 24px;
  width: 400px;
  height: 46px;

  @media (max-width: 1024px) {
    width: 323px;
  }
}

.ButtonsCol {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: 347px;
    background: $white;
    box-shadow: 0px 3px 5px 0px #0000000d;
    padding: 16px;
    border-radius: 10px 10px 10px 10px !important;
  }
}

.MilaIcon {
  max-width: 30px;
}
