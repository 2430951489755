@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.ShareModalWrapper {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 36px auto 64px auto;

  @include breakpoint(desktop) {
    max-width: 480px;
  }
}

.FormCol {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.Title {
  font-size: 24px;
  font-weight: 600;
  color: $primaryDark;
}

.Image.Image {
  width: 256px;
  height: 165px;
}

.ShareButton {
  width: 100%;
  height: 44px;
}

.Col {
  flex-direction: column;
  gap: 14px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
}

.SubTitle {
  align-self: flex-start;
  font-size: 16px;
  font-weight: 600;
}

.CheckBoxRow {
  width: 100%;
  gap: 10px;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.CheckBoxItem {
  margin-bottom: 0;
  :global(.ant-form-item-control-input) {
    min-height: auto;
  }
}

.Input {
  width: 100%;
  margin-bottom: 0;
}

.ShareModalContainer {
  :global(.ant-modal-content) {
    background-color: $background;
  }
}

.MilaMessageContainer {
  padding: 16px;
  gap: 14px;
}

.GreyText {
  color: $textGrey;
  letter-spacing: -0.28px;
}
