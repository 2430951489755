@import 'src/styles/mixins';
@import 'src/app/theme.scss';

.PromoRow {
  width: 100%;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  @include breakpoint(desktop) {
    margin-top: 0;
    flex-direction: row;
    padding: 23px;
  }
}

.MainRow {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @include breakpoint(desktop) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

.PromoCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  @include breakpoint(desktop) {
    width: 50%;
  }
}

.Title {
  font-size: 16px;
  font-weight: 600;

  @include breakpoint(desktop) {
    font-size: 24px;
  }
}

.DateRow {
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
}

.PaleText {
  color: $textGrey;
}

.Button {
  width: 100%;
  height: 36px;
}

.DoctorsRow {
  display: flex;
  width: 100%;
  gap: 14px;

  @include breakpoint(desktop) {
    gap: 20px;
  }
}

.NetworkLogo.NetworkLogo {
  position: absolute;
  max-width: 90px;
  max-height: 90px;
  border-radius: 6px;
  bottom: 9px;
  left: 9px;
  background: $white;
  padding: 9px;
}

.PictureLogoWrapper {
  flex-direction: column;
  width: 100%;
}

.NetworkBanner.NetworkBanner {
  width: 270px;
  height: 180px;
  border-radius: 8px;
  object-fit: cover;

  @include breakpoint(desktop) {
    width: 375px;
    height: 250px;
  }
}
