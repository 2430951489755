@import 'src/app/theme.scss';
@import 'src/styles/mixins';

.GosCol {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  max-width: 210px;
}

.GosButton {
  height: 44px;
  color: $primary;
}

.GosRow {
  padding: 20px;
  @include breakpoint(desktop) {
    display: none;
  }
}

.RowType {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  flex-direction: column;

  @include breakpoint(desktop) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  & :global {
    .ant-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include breakpoint(desktop) {
        flex-direction: row;
      }
    }
  }

  .GreySmallText {
    margin: 0 0 10px;

    @include breakpoint(desktop) {
      max-width: 318px;
      display: flex;
      flex-direction: row;
      margin: 0 0 0 30px;
      text-align: left;
    }
  }

  .GosImage {
    max-width: 74px;
  }
}

.GosImage {
  max-width: 60px;
}

.Title {
  color: $textPrimary;
  font-size: 14px;
  font-weight: 600;
}

.GreySmallText {
  font-size: 12px;
  color: $textGrey;
  text-align: center;
}
